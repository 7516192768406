import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
    selector: 'modal-default',
    templateUrl: 'modal-default-component.html',
    styleUrls: ['modal-default-component.scss']
})

export class ModalDefaultComponent implements OnInit {

    titulo: any;
    texto: any;
    number: any;
    link: any;
    data: any;
    icone: any;
    status: any;
    cssClass: any;
    btnLabel: any;
    btnLabel2: any;
    jogoInfo: any;
    close = false;

    constructor(private modalCtrl: ModalController,
                private navParams: NavParams,
                private clipboard: Clipboard,
                private navCtrl: NavController,
                private iab: InAppBrowser) {

        this.titulo = this.navParams.get('titulo');
        this.texto = this.navParams.get('texto');
        this.number = this.navParams.get('number');
        this.link = this.navParams.get('link');
        this.data = this.navParams.get('data');
        this.icone = this.navParams.get('icone');
        this.status = this.navParams.get('status');
        this.cssClass = this.navParams.get('cssClass');
        this.btnLabel = this.navParams.get('btnLabel');
        this.btnLabel2 = this.navParams.get('btnLabel2');
        this.jogoInfo = this.navParams.get('jogoInfo');
        this.close = this.navParams.get('close');
    }

    async ngOnInit() {
        console.log('ngOnInit - > ModalDefaultComponent');

        if (!this.btnLabel) {
            this.btnLabel = 'Obrigado';
        }
    }

    copy() {
        // console.log(this.number);
        // this.clipboard.copy(this.number);

        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.number;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

    }

    onClose() {
        this.modalCtrl.dismiss(false);
        //this.navCtrl.navigateRoot('menu/home');
    }
    onCloseDirect() {
        this.modalCtrl.dismiss(true);
        this.navCtrl.navigateRoot('menu/home');
    }

    onContinue() {
        this.modalCtrl.dismiss(true);
    }

    async open() {
        await this.iab.create(this.link, '_system')
    }
}
