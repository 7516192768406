import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, LoadingController, ToastController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/services/user/user.service';

@Component({
    selector: 'modal-cadastrar-cartao',
    templateUrl: 'modal-cadastrar-cartao-component.html',
    styleUrls: ['modal-cadastrar-cartao-component.scss']
})

export class ModalCadastrarCartaoComponent implements OnInit {

    public formGroup = new FormGroup({
        numCard: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        validityCard: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        nameCard: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        cvv: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        cpf: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        rua: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        complemento: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        numero: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        bairro: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        cidade: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        estado: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        cep: new FormControl('', {
            validators: [
                Validators.required,
            ]
        })
    });

    address: {
        rua: string,
        complemento: string,
        bairro: string,
        cidade: string,
        estado: string,
    }

    titulo: any;
    numCartao = '';
    cep = '';
    brand = 'Visa';
    addressValid = false;

    constructor(private modalCtrl: ModalController,
        private navParams: NavParams,
        private loadingCtrl: LoadingController,
        private userService: UserService,
        private toastCtrl: ToastController) {
        this.titulo = this.navParams.get('titulo');
    }

    async ngOnInit() {
        console.log('ngOnInit - > ModalCadastrarCartaoComponent');
    }

    async inputCep(event) {
        if (this.cep && this.cep.length >= 9) {
            const address: any = await this.userService.pegaEndereco(this.cep);
            this.address = address;
            this.addressValid = true;
        } else {
            this.address = {
                rua: '',
                complemento: '',
                bairro: '',
                cidade: '',
                estado: '',
            };
            this.addressValid = false;
        }
    }

    inputNumCartao(evt) {
        if (evt.detail.data != null) {
            if (this.numCartao.length === 4 || this.numCartao.length === 9 || this.numCartao.length === 14) {
                this.numCartao += ' ' + evt.detail.data;
            } else {
                this.numCartao += evt.detail.data;
            }
            this.getTypeCard();
        }
    }

    invalidCPF(cpf) {
        console.log('((((((((((((((', cpf);
        if (cpf === '000.000.000-00' || cpf === '111.111.111-11' || cpf === '222.222.222-22' ||
            cpf === '333.333.333-33' || cpf === '444.444.444-44' || cpf === '555.555.555-55' ||
            cpf === '666.666.666-66' || cpf === '777.777.777-77' || cpf === '888.888.888-88' ||
            cpf === '999.999.999-99') {
            return true;
        }
        return false;
    }

    async onRegister() {

        let form = this.formGroup.value;

        if (this.invalidCPF(form.cpf)) {
            const toast = await this.toastCtrl.create({
                message: 'Cpf invalido!',
                position: 'top',
                duration: 3000
            });
            return;
        }

        let now = new Date();


        if ((now.getFullYear() + '').substr(2, 2) > form.validityCard.substr(3, 2)) {
            const toast = await this.toastCtrl.create({
                message: 'Validade do cartão invalido!',
                position: 'top',
                duration: 3000
            });
            toast.present();
            return;
        } else if ((now.getFullYear() + '').substr(2, 2) == form.validityCard.substr(3, 2) && (now.getMonth() + 1) > form.validityCard.substr(0, 2)) {
            const toast = await this.toastCtrl.create({
                message: 'Validade do cartão invalido!',
                position: 'top',
                duration: 3000
            });
            toast.present();
            return;
        }

        const loading = await this.loadingCtrl.create();
        await loading.present();


        form.brand = await this.getCardType(form.numCard.replace(/\s/g, ''));;
        const user: any = await this.userService.cardAdd(form);
        if (user && user._id) {
            await loading.dismiss();
            const toast = await this.toastCtrl.create({
                message: 'Cartão cadastrado com sucesso!',
                position: 'top',
                duration: 3000
            });
            await toast.present();
            this.modalCtrl.dismiss(this.formGroup.value);
        } else {
            await loading.dismiss();
            const toast = await this.toastCtrl.create({
                message: 'Ocorreu um erro ao cadastrar!',
                position: 'top',
                duration: 3000
            });
            await toast.present();
        }

    }

    onClose() {
        this.modalCtrl.dismiss();
    }

    async getTypeCard() {
        this.brand = await this.getCardType(this.numCartao.replace(/\s/g, ''));
    }

    async getCardType(number) {
        // visa
        var re = new RegExp("^4");
        if (number.match(re) != null)
            return "Visa";

        // Mastercard
        // Updated for Mastercard 2017 BINs expansion
        if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
            return "Master";

        // AMEX
        re = new RegExp(/^3[47]/);
        if (number.match(re) != null)
            return "AMEX";

        // JCB
        re = new RegExp(/^35(2[89]|[3-8][0-9])/);
        if (number.match(re) != null)
            return "JCB";

        // Elo
        re = new RegExp(/^4011|438935|45(1416|76)|50(4175|6699|67|90[4-7])|63(6297|6368)/);
        if (number.match(re) != null)
            return "Elo";

        // Diners
        re = new RegExp(/^36/);
        if (number.match(re) != null)
            return "Diners";

        // Diners - Carte Blanche
        re = new RegExp(/^30[0-5]/);
        if (number.match(re) != null)
            return "Diners";

        // Diners - Carte Blanche
        re = new RegExp(/^3(0[0-5]|[68]\d)\d{11}$/);
        if (number.match(re) != null)
            return "Diners";

        // Diners - Carte Blanche
        re = new RegExp(/^(?:2131|1800|35\d{3})\d{11}$/);
        if (number.match(re) != null)
            return "JCB";

        // Diners - Carte Blanche
        re = new RegExp(/^(606282\d{10}(\d{3})?)|(3841\d{15})$/);
        if (number.match(re) != null)
            return "Hipercard";

        // Visa Electron
        re = new RegExp(/^(4026|417500|4508|4844|491(3|7))/);
        if (number.match(re) != null)
            return "Visa";

        // Discover
        re = new RegExp(/^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/);
        if (number.match(re) != null)
            return "Elo";

        return "Visa";
    }
}
