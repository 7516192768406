import { Injectable } from '@angular/core';
import { RequestService } from '../api/request.service';
import { URL_BASE } from 'src/util/constants';

@Injectable({
    providedIn: 'root'
})
export class RifasService {

    constructor(
        private http: RequestService
    ) {
    }

    async getRifas() {
        return await this.http.get(`${URL_BASE}/rifa/disponiveis`);
    }

    async getRifasUsuario() {
        return await this.http.get(`${URL_BASE}/rifa/usuario`);
    }

    async getRifaDetails(id: string) {
        return await this.http.get(`${URL_BASE}/rifa/details/${id}`);
    }

    async getRifasAtivas() {
        return await this.http.get(`${URL_BASE}/rifa/ativas-usuario`);
    }

    async getRifasPremiadas() {
        return await this.http.get(`${URL_BASE}/rifa/premiadas-usuario`);
    }

    async getRifasSorteadas() {
        return await this.http.get(`${URL_BASE}/rifa/sorteadas`);
    }

    async getResultadoRifa(id) {
        return await this.http.get(`${URL_BASE}/rifa/resultado-rifa/${id}`);
    }
}
