import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RifasService} from 'src/services/rifas/rifas.service';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {environment} from "../../../environments/environment";

@Component({
    selector: 'raffle-reward-slider',
    templateUrl: 'raffle-reward-slider.html',
    styleUrls: ['./raffle-reward-slider.scss']
})
// tslint:disable-next-line:component-class-suffix
export class RaffleRewardSlider implements OnInit {
    isMobile;
    awards: any;
    idSelecionado = null;
    canLoad: boolean;
    rifaIsLoaded = false;
    resizeSubscription: Subscription | null = null;

    @Input() isRifaPage = false;
    @Output() selecionar = new EventEmitter();
    @Output() premios = new EventEmitter();

    slideOptions: any;

    sliderOptionsSkeletton = {
        slidesPerView: 'auto'
    };

    constructor(
        private rifasService: RifasService
    ) {
        this.isMobile = window.innerWidth < 700;
    }

    async ngOnInit(): Promise<void> {
        await this.getRewards();
        let numberOfSlides: any;

        this.awards.length > 3 ? numberOfSlides = 'auto' : numberOfSlides = this.awards.length;

        this.slideOptions = {
            slidesPerView: numberOfSlides,
            autoplay: true,
            spaceBetween: 20,
            breakpoints: {
                750: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                1400: {
                    slidesPerView: 2,
                    spaceBetween: 20
                }
            },
        };

        this.isMobile && this.isRifaPage ? this.canLoad = false : this.canLoad = true;

        const savedIdSelecionado = localStorage.getItem('idSelecionado');
        const selectedAward = this.awards.find(award => award.id === savedIdSelecionado);

        if (this.isRifaPage && !this.isMobile && (selectedAward || this.awards.length)) {
            return this.selecionarRifa(selectedAward || this.awards[0]);
        }
    }

    selectPhoto(photo: string): string {
        return  environment.apiUrl + `/api/premio/photo/public/${photo}`;
    }

    async getRewards() {
        try {
            const response: any = await this.rifasService.getRifas();
            this.awards = response.docs;
            this.awards.forEach(award => {
                if (award.status === 'sorteado') {
                    award.label = 'Sorteado';
                } else if (award.status === 'em-andamento') {
                    award.label = award.label ? award.label : 'Em andamento';
                    if (moment(award.dataSorteio).isBefore(moment())) {
                        award.label = 'Encerrado';
                    }
                }
            });
            this.rifaIsLoaded = true;
        } catch (err) {
            console.error(err);
        }
    }

    selecionarRifa(award: any) {
        this.idSelecionado = award.id;
        localStorage.setItem('idSelecionado', this.idSelecionado); // Salva o idSelecionado no localStorage
        this.selecionar.emit(award);
    }

    isAwardSelected(award: any) {
        return this.isRifaPage && award.id === this.idSelecionado;
    }
}
