import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'modal-tipo-aposta',
  templateUrl: 'modal-tipo-aposta-component.html',
  styleUrls: ['modal-tipo-aposta-component.scss']
})

export class ModalTipoApostaComponent implements OnInit {

  listType: any[] = [
    'Grupo',
    'Dezena',
    'Centena',
    'Milhar',
    'Duque de dezenas',
    'Duque de grupo',
    'Terno de dezenas',
    'Terno de grupo',
    'Milhar/Centena',
  ];

  constructor(private modalCtrl: ModalController) {
  }

  async ngOnInit() {}

  onSelectType(type) {
    this.modalCtrl.dismiss(type);
  }

  onClose() {
    this.modalCtrl.dismiss();
  }
}
