import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-card-jogo',
  templateUrl: './card-jogo.component.html',
  styleUrls: ['./card-jogo.component.scss'],
})
export class CardJogoComponent implements OnInit {

  @Output() onSelectCard = new EventEmitter();

  @Input() lista: any[] = [];

  @Output() onPage = new EventEmitter();
  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  formatValue(valor) {
    return (valor / 100).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}).replace("R$", "");
  }

  emitSelectCard(game) {
    this.onSelectCard.emit({
      game
    });
  }

  async infinit(evt) {
    this.onPage.emit({ });
    evt.target.complete();
  }


  verTodos() {
    this.router.navigateByUrl('/menu/extract#jogos');
  }
}
