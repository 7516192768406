export class Util {
    static getTextFormat(text) {
        const splittedValue = text.split(' ').map((segment) => {
            return segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase();
        });

        return splittedValue.join(' ');
    }

    static formatEmail(email: any) {
        return email.toLowerCase();
    }

    static validEmail(email: any) {
        const usuario = email.substring(0, email.indexOf('@'));
        const dominio = email.substring(email.indexOf('@') + 1, email.length);

        if ((usuario.length >= 1) &&
            (dominio.length >= 3) &&
            (usuario.search('@') === -1) &&
            (dominio.search('@') === -1) &&
            (usuario.search(' ') === -1) &&
            (dominio.search(' ') === -1) &&
            (dominio.search('.') !== -1) &&
            (dominio.indexOf('.') >= 1) &&
            (dominio.lastIndexOf('.') < dominio.length - 1)) {
            return 'valido';
        } else {
            return 'invalido';
        }
    }

    static formatPhone(phone: any) {
        // enquanto digita o num.
        // const v = phone;
        // if (v.length === 3) {
        //     phone = v + ') 9';
        // }
        //
        // return phone;

        // caso seja no onBlur
        let vl = phone;
        if (vl.length === 14) {
            vl = vl.slice(0, 5) + '9' + vl.slice(5);
        }

        return vl;
    }

    static removeAcentos(value: string) {
        const mapHexAcentos = {
            a: /[\xE0-\xE6]/g,
            A: /[\xC0-\xC6]/g,
            e: /[\xE8-\xEB]/g,
            E: /[\xC8-\xCB]/g,
            i: /[\xEC-\xEF]/g,
            I: /[\xCC-\xCF]/g,
            o: /[\xF2-\xF6]/g,
            O: /[\xD2-\xD6]/g,
            u: /[\xF9-\xFC]/g,
            U: /[\xD9-\xDC]/g,
            c: /\xE7/g,
            C: /\xC7/g,
            n: /\xF1/g,
            N: /\xD1/g,
        };

        for (const char in mapHexAcentos) {
            const regExp = mapHexAcentos[char];
            value = value.replace(regExp, char);
        }

        return value;
    }

    static validateCpf(value) {
        let Soma;
        let Resto;
        Soma = 0;
        if (value == '00000000000') { return false; }

        for (let i = 1; i <= 9; i++) { Soma = Soma + parseInt(value.substring(i - 1, i)) * (11 - i); }
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) { Resto = 0; }
        if (Resto != parseInt(value.substring(9, 10))) { return false; }

        Soma = 0;
        for (let i = 1; i <= 10; i++) { Soma = Soma + parseInt(value.substring(i - 1, i)) * (12 - i); }
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) { Resto = 0; }
        if (Resto != parseInt(value.substring(10, 11))) { return false; }
        return true;
    }

    static brNumberToUs(numero, fixed = 2) {
        return parseFloat(`${numero}`.toString().trim().replace('.', '').replace(',', '.')).toFixed(fixed);
    }

    static usNumberToBr(numero, fixed = 2) {
        return parseFloat(numero).toFixed(fixed).toString().replace('.', ',');
    }

}
