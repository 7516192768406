import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AlertController, ToastController } from "@ionic/angular";

@Component({
  selector: 'add-valor-component',
  templateUrl: 'add-valor-component.html',
  styleUrls: ['./add-valor-component.scss']
})

export class AddValorComponent implements OnInit, OnChanges {

  @Output() onChangeValue = new EventEmitter();

  @Input() classCSS: string;
  @Input() defaultValue: string;
  @Input() maxValue;
  @Input() minValue = 1;
  @Input() step = 1;
  @Input() tipo: string;
  @Input() tipoJgo: any;
  @Input() totalBalance: any;
  @Input() total: any;
  @Input() blockDelValue: boolean = false;
  @Input() listaValores: any[] = [2, 5, 10, 20];

  valorSelecionado: string = '1,00';
  classError = false;
  valorRange: number = 0;

  constructor(private toastCtrl: ToastController) {
  }

  ngOnInit() {
    this.valorSelecionado = this.defaultValue;
    this.emitChangeValue();
    if (this.tipo === 'resgatar' && this.total >= 0) {
      this.maxValue = this.total;
      this.totalBalance = this.totalBalance.replace("R$", "");

      console.log(this.totalBalance);
      console.log(this.maxValue);
      console.log(this.total);

      this.listaValores = [];
      const array = [2, 5, 10, 20];

      array.forEach(item => {
        if (item <= this.maxValue)
          this.listaValores.push(item);
      });
    }
    if (this.tipoJgo) {
      this.maxValue;
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('tipoJgo' in changes) {
      this.tipoJgo = changes.tipoJgo.currentValue;
      this.maxValue = this.getMaxValue(this.tipoJgo);
    }
  }

  focusInput(evt) {

  }

  changeInput(evt) {
    let v = 0;
    if (evt.detail) {
      v = (parseInt(evt.detail.value.replace(',', ''))) / 100;
    }

    if (evt.detail && v <= this.maxValue) {
      this.valorSelecionado = evt.detail.value;
      this.classError = false;
    } else if ((evt.detail && v > this.maxValue) && this.tipoJgo) {
      this.classError = true;
      this.toast(`Valor máximo para esta aposta é ${(this.maxValue).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
        })}`);
    } else if (!this.tipoJgo) {
      this.valorSelecionado = evt.detail.value;
    }

    this.emitChangeValue();
  }

  changeRange(evt) {
    if (evt.detail && evt.detail.value) {
      this.valorSelecionado = evt.detail.value + ',00';
    }

    this.emitChangeValue();
  }

  async toast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      position: 'top',
      duration: 1200,
      color: 'danger'
    });
    toast.present();
  }

  addValor(value: any) {
    if (value) {
      this.valorSelecionado = value + ",00";
    } else {
      let v = parseInt(this.valorSelecionado.replace(',', ''))

      this.valorSelecionado = (((v + 100) / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).replace("R$", "")).split(".").join("");
    }

    this.emitChangeValue();
  }

  delValor() {
    let v = parseInt(this.valorSelecionado.replace(',', ''));

    if (this.blockDelValue) {
      const t = (v / 100);
      
      if (t <= this.minValue) {
        return false;
      }

    }

    if (v > 100) {
      this.valorSelecionado = ((v - 100) / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).replace("R$", "").split(".").join("");
    }
    this.emitChangeValue();
  }

  emitChangeValue() {
    this.onChangeValue.emit({
      data: this.valorSelecionado
    });
  }

  getMaxValue(tipo) {
    const obj = {
      Grupo: 3500,
      Dezena: 1000,
      Centena: 100,
      Milhar: 50,
      'Duque de dezenas': 200,
      'Duque de grupo': 3000,
      'Terno de dezenas': 30,
      'Terno de grupo': 450,
    };
    return obj[tipo];
  }
}
