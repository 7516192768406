import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
    selector: 'modal-lottery',
    templateUrl: 'modal-lottery-component.html',
    styleUrls: ['modal-lottery-component.scss']
})

export class ModalLotteryComponent implements OnInit {

    titulo: any;
    number: any;
    cpf: any;
    close = false;

    constructor(private modalCtrl: ModalController,
                private navParams: NavParams,
                private clipboard: Clipboard,
                private navCtrl: NavController,
                private iab: InAppBrowser) {

        this.titulo = this.navParams.get('titulo');
        this.number = this.navParams.get('number');
        this.cpf = this.navParams.get('cpf');
    }

    async ngOnInit() {
        console.log('ngOnInit - > ModalDefaultComponent');
    }

    copy() {
        // console.log(this.number);
        // this.clipboard.copy(this.number);

        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.number;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

    }

    onClose() {
        this.modalCtrl.dismiss(false);
        //this.navCtrl.navigateRoot('menu/home');
    }
    onCloseDirect() {
        this.modalCtrl.dismiss(true);
        this.navCtrl.navigateRoot('menu/home');
    }

    onContinue() {
        this.modalCtrl.dismiss(true);
    }
}
