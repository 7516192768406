import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, NavParams } from '@ionic/angular';

@Component({
    selector: 'modal-duvida-detalhe',
    templateUrl: 'modal-duvida-detalhe-component.html',
    styleUrls: ['modal-duvida-detalhe-component.scss']
})

export class ModalDuvidaDetalheComponent implements OnInit {

    detatlhe: any;

    constructor(private modalCtrl: ModalController,
                private navParams: NavParams) {
        this.detatlhe = navParams.get('detalhe');
    }

    async ngOnInit() {
        console.log('ngOnInit - > ModalDuvidaDetalheComponent');
    }

    onClose() {
        this.modalCtrl.dismiss();
    }
}
