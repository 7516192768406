import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, ToastController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Util } from '../../../util/utils';

@Component({
    selector: 'modal-jogo-cadastro',
    templateUrl: './modal-jogo-cadastro-component.html',
    styleUrls: ['./modal-jogo-cadastro-component.scss'],
})

export class ModalJogoCadastroComponent implements OnInit {

    public formGroup = new FormGroup({
        name: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        email: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),

        numCard: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        validityCard: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        nameCard: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        cvv: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        acceptTerm: new FormControl(false, {
            validators: [
                Validators.required,
            ]
        })
    });

    numCartao = '';

    constructor(private modalCtrl: ModalController,
                public toastCtrl: ToastController, ) {
    }

    async ngOnInit() {
        console.log('ngOnInit - > ModalJogoCadastroComponent');
    }

    inputNumCartao(evt) {
        if (evt.detail.data != null) {
            if (this.numCartao.length === 4 || this.numCartao.length === 9 || this.numCartao.length === 14) {
                this.numCartao += ' ' + evt.detail.data;
            } else {
                this.numCartao += evt.detail.data;
            }
        }
    }

    pay() {
        this.modalCtrl.dismiss('apostar');
    }

    async validEmail(evt) {
        const status = Util.validEmail(evt.target.value);

        if (status === 'invalido') {
            const toast = await this.toastCtrl.create({
                message: 'E-mail inválido.',
                cssClass: 'toast-error',
                position: 'top',
                duration: 3000
            });

            return toast.present();
        }
    }

    formatEmail(evt) {
        evt.target.value = Util.formatEmail(evt.target.value);
    }

    onClose() {
        this.modalCtrl.dismiss();
    }
}
