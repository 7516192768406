import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, LoadingController, ToastController } from '@ionic/angular';
import { UserService } from 'src/services/user/user.service';
import {SITE_KEY} from "../../../util/constants";

@Component({
  selector: 'modal-transferencia',
  templateUrl: 'modal-transferencia-component.html',
  styleUrls: ['modal-transferencia-component.scss']
})

export class ModalTransferenciaComponent implements OnInit {

  info: any;
  valor: any;
  newContact: any;
  transfer: any;
  password: any;
  captcha: any;
  SITE_KEY = SITE_KEY;

  constructor(private modalCtrl: ModalController,
              private navParams: NavParams,
              private loadingCtrl: LoadingController,
              private toastCtrl: ToastController,
              private userService: UserService) {

    this.info = this.navParams.get('data');
    this.valor = this.navParams.get('valor');
    this.newContact = this.navParams.get('newContact');
    this.transfer = this.navParams.get('transfer');
    // console.log('newContact----', this.info);
  }

  async ngOnInit() {
    console.log('ngOnInit - > ModalTransferenciaComponent');
  }

  setFocus() {
    const element: any = document.getElementsByClassName('fab-modal-action')[0];
    element.style.height = '100%';
  }

  async onConfirm() {

    const element: any = document.getElementsByClassName('fab-modal-action')[0];
    element.style.height = '';
    const loading = await this.loadingCtrl.create();
    await loading.present();

    const form = this.info;
    form.valor = (this.valor) + '';
    form.password = this.password;
    form.captcha = this.captcha;
    const user: any = await this.userService.transfer(form);
    if (user && user.validate === true) {
      await loading.dismiss();
      this.modalCtrl.dismiss(true);
    } else {
      await loading.dismiss();
      const toast = await this.toastCtrl.create({
        message: user.msg,
        position: 'top',
        duration: 6000,
        color: user.validate ? 'primary' : 'danger'
      });
      await toast.present();
    }
  }

  onClose() {
    const element: any = document.getElementsByClassName('fab-modal-action')[0];
    element.style.height = '';
    this.modalCtrl.dismiss();
  }


  getValue() {
    return (this.valor / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).replace('R$', '').split('.').join('');
  }

  getValueReceber() {
    let calc = Math.trunc(this.valor * .94) / 100;
    if (this.transfer) {
      calc = this.valor / 100;
    } else {
      const porcentagemseis = 100 / 6;
      calc = this.valor / 100;
      calc = calc - ((calc) / porcentagemseis);
    }

    return calc.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).replace('R$', '').split('.').join('');
  }

  onCaptchaResponse(captcha: any) {
    this.captcha = captcha;
  }
}
