import { Component, OnInit, ViewChild, HostBinding } from '@angular/core';

import { AlertController, IonMenu, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { Router } from '@angular/router';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    @HostBinding('class.has-global-footer') public globalFooterEnabled = true;

    constructor(private platform: Platform,
                private navCtrl: NavController,
                private storage: Storage,
                private alertCtrl: AlertController,
                private splashScreen: SplashScreen,
                private statusBar: StatusBar,
                public router: Router,
                private mobileAccessibility: MobileAccessibility
    ) {

        if (platform.width() < 991) {
            this.globalFooterEnabled = false;
        }

        this.initializeApp();
    }

    ngOnInit() {
        // const path = window.location.pathname.split('folder/')[1];
        //
        // if (path !== undefined) {
        //     this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
        // }
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.mobileAccessibility.setTextZoom(10);
            this.mobileAccessibility.updateTextZoom();
            this.mobileAccessibility.usePreferredTextZoom(false);
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }
}
