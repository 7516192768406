import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as moment from 'moment';
import {Util} from '../../../util/utils';

@Component({
    selector: 'card-extract-component',
    templateUrl: 'card-extract-component.html',
    styleUrls: ['./card-extract-component.scss']
})

export class CardExtractComponent implements OnInit, OnChanges {

    @Output() onChangeValue = new EventEmitter();
    @Output() verQrCode = new EventEmitter();
    @Output() carregarMais = new EventEmitter();

    @Input() classCSS: string;
    @Input() transferencias = [];
    @Input() defaultValue: any;
    extratos = [];
    typeSelected: any;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.transferencias && changes.transferencias.currentValue && changes.transferencias.currentValue.length) {
            const extratos = this.cards(changes.transferencias.currentValue);
            this.extratos = extratos;
        }
    }

    ngOnInit() {
    }

    getFormattedDate(date: Date): string {
        return moment(date).format('DD/MM/YYYY HH:mm') + 'h';
    }

    onSelectType(type) {
        const detail = {
            tipo: type
        };

        this.onChangeValue.emit({
            tipo: type
        });
    }

    verQr(dadosPagamento) {
        this.verQrCode.emit(dadosPagamento);
    }

    cards(transferencias: any) {
        const extratos = [];
        for (const item of transferencias) {
            item.status = `${item.status}`.toLowerCase();
            item.tipo = `${item.tipo}`.toLowerCase();
            if (item.tipo === 'aposta' && item.rifa) {
                extratos.push({
                    nome: 'Compra de cotas',
                    img: '/assets/icons/icon-casino.svg',
                    tipo: 'Rifa - ' + item.rifa.nome,
                    valor: item.valorAposta,
                    valorReais: item.valorAposta / 100,
                    color: 'red',
                    data: item.created_at
                });
            } else if (item.tipo === 'premiacao' && item.valorPremio) {
                extratos.push({
                    nome: 'Rifa premiada - ' + item.rifa.nome,
                    img: '/assets/icons/icon-casino.svg',
                    tipo: 'Prêmio rifa',
                    valor: item.valor,
                    valorReais: item.valorPremio,
                    color: 'green',
                    data: item.created_at
                });
            } else if (item.tipo === 'maior_comprador' && item.valorPremio) {
                extratos.push({
                    nome: 'Maior comprador rifa - ' + item.rifa.nome,
                    img: '/assets/icons/icon-casino.svg',
                    tipo: 'Total de cotas: ' + item.qtdCotas,
                    valor: item.valor,
                    valorReais: item.valorPremio,
                    color: 'green',
                    data: item.created_at
                });
            } else if (item.status === 'sorteado' && item.valor_premio && item.wins && item.wins.includes(item.user._id)) {
                const valor = item.valor_premio / 100;
                const valorReais = Util.brNumberToUs(valor);
                extratos.push({
                    nome: 'Aposta premiada',
                    img: '/assets/icons/icon-casino.svg',
                    tipo: item.tipo,
                    valor: valorReais,
                    valorReais: parseFloat(valorReais),
                    color: 'green',
                    data: item.created_at
                });
            } else if (item.status === 'sorteado' && item.valor_premio && item.wins && !item.wins.includes(item.user._id)) {
                const valor = item.valor_premio / 100;
                extratos.push({
                    nome: 'Aposta realizada',
                    img: '/assets/icons/icon-casino.svg',
                    tipo: item.tipo,
                    valor: item.valor,
                    valorReais: item.valor / 100,
                    color: 'red',
                    data: item.created_at
                });
            } else if (item.status === 'apostado' || item.status === 'não sorteado') {
                extratos.push({
                    nome: 'Aposta realizada',
                    img: '/assets/icons/icon-casino.svg',
                    tipo: item.tipo,
                    valor: item.valor,
                    valorReais: item.valor / 100,
                    color: 'red',
                    data: item.created_at
                });
            } else if (item.tipo === 'pix' && item.status === 'aprovado') {
                extratos.push({
                    nome: 'Deposito',
                    img: '/assets/icons/pix.svg',
                    tipo: 'Pagamento efetuado',
                    valorReais: item.valor / 100,
                    valor: item.valor,
                    color: 'green',
                    data: item.created_at
                });
            } else if (item.tipo === 'pix' && item.status === 'pendente') {
                extratos.push({
                    nome: 'Deposito pendente',
                    img: '/assets/icons/pix.svg',
                    tipo: 'Pagamento pedente',
                    valorReais: item.valor / 100,
                    valor: item.valor,
                    color: 'yellow',
                    data: item.created_at,
                    status: item.status,
                    logPix: item.logPix,
                    verQR: true
                });
            }  else if (item.tipo === 'pix' && item.status === 'expirado') {
                extratos.push({
                    nome: 'Deposito expirado',
                    img: '/assets/icons/pix.svg',
                    tipo: 'Pagamento expirado',
                    valorReais: item.valor / 100,
                    valor: item.valor,
                    color: 'yellow',
                    data: item.created_at,
                    status: item.status,
                    verQR: false
                });
            } else if (item.tipo === 'bonus' && item.status === 'aprovado') {
                extratos.push({
                    nome: 'Bônus',
                    img: '/assets/icons/icon-deal.svg',
                    tipo: item.code_promo === 'PRIMEIRA_RECARGA' ? 'Bônus primeira recarga' : 'Bônus',
                    valorReais: item.valor / 100,
                    valor: item.valor,
                    color: 'green',
                    data: item.created_at,
                    status: item.status,
                });
            } else if (item.tipo === 'transferencia' && item.status === 'pago') {
                extratos.push({
                    nome: 'Saque realizado',
                    img: '/assets/icons/icon-transfer.svg',
                    tipo: '',
                    valorReais: item.valor / 100,
                    cpf: item.bc_cpf,
                    destinatario: item.bc_nome,
                    valor: item.valor,
                    color: 'red',
                    data: item.created_at,
                    status: item.status,
                });
            } else if (item.tipo === 'transferencia' && item.status === 'pendente') {
                extratos.push({
                    nome: 'Saque solicitado',
                    img: '/assets/icons/icon-transfer.svg',
                    tipo: 'Pendente de aprovação',
                    valorReais: item.valor / 100,
                    cpf: item.bc_cpf,
                    destinatario: item.bc_nome,
                    valor: item.valor,
                    color: 'yellow',
                    data: item.created_at,
                    status: item.status,
                });
            }
        }
        return extratos;
    }

    async carregarMaisItens() {
        this.carregarMais.emit();
    }
}
