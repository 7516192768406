import { Injectable } from '@angular/core';
import { RequestService } from '../api/request.service';
import { URL_BASE } from 'src/util/constants';

@Injectable({ providedIn: 'root' })
export class CotasService {

  constructor(
    private http: RequestService
  ) { }

  async getCotas(id: string) {
    return await this.http.get(`${URL_BASE}/cotas/disponiveis/${id}`);
  }

  async postBuyQuotes({ form }) {
    return await this.http.post(`${URL_BASE}/cotas/`, { body: { ...form } });
  }

  async cotasDisponiveis(id, qtdCotas) {
    return await this.http.post(`${URL_BASE}/cotas/cotas-disponiveis/${id}`, { body: qtdCotas });
  }

}
