import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalContactComponent } from '../../modal-components/modal-contact/modal-contact-component';
import { ModalDefaultComponent } from '../../modal-components/modal-default/modal-default-component';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';


// declare var ceg_1c1269af_607b_449d_87c1_d2839c367962;

@Component({
  selector: 'footer-component',
  templateUrl: 'footer-component.html',
  styleUrls: ['./footer-component.scss']
})

export class FooterComponent implements OnInit {

  typeSelected: any;
  show = true;
  isPWA = false;
  path = '';

  @Input() welcome: any;

  @ViewChild('cea', { static: false }) cea: ElementRef;

  constructor(
      public router: Router,
      private platform: Platform,
      private modalCtrl: ModalController) {

    if (this.platform.is('desktop') || this.platform.is('pwa')) {
      this.isPWA = true;
    }

    if (this.platform.is('cordova')) {
      this.show = false;
    }
  }

  ngOnInit() {
    if (this.router.url === '/menu/home') {
      this.path = '/menu';
    }
    // setTimeout(()=>{
    //   ceg_1c1269af_607b_449d_87c1_d2839c367962.init();
    // }, 1000)
  }

  openCuracao() {
    window.open('https://validator.curacao-egaming.com', '_blank');
  }

  openPDF() {
    window.open('assets/pdf/certificado.pdf', '_blank');
  }

  async openPolitica() {
    this.router.navigate([this.path + '/privacidade']);
  }

  openFaq() {
    this.router.navigate([this.path + '/aprender-jogar']);
  }

  openContato() {
    this.router.navigate([this.path + '/central-duvidas']);
  }

  openTermo(link?) {
    if (link) {
      this.router.navigate([this.path + '/termo-uso', {link}]);
    } else {
      this.router.navigate([this.path + '/termo-uso']);
    }
  }

  openAML() {
    window.open('assets/pdf/Animalbet__aml-kyc-policy.pdf', '_blank');
  }

  openReclamacao() {
    this.router.navigate([this.path + '/reclamacoes']);
  }

  openQuem() {
    this.router.navigate([this.path + '/quem']);
  }

  openJogos() {
    this.router.navigate([this.path + '/responsavel']);
  }

  async onContactUs() {
    const modal = await this.modalCtrl.create({
        component: ModalContactComponent,
        keyboardClose: true,
        cssClass: 'modal-fab-bottom modal-contact',
        componentProps: {}
      }
    );

    modal.onDidDismiss().then((info: any) => {
      if (info && info.data) {
        this.openModalSuccess();
      }
    });
    return await modal.present();
  }

  async openModalSuccess() {
    const modal = await this.modalCtrl.create({
        component: ModalDefaultComponent,
        keyboardClose: true,
        cssClass: 'modal-fab-bottom modal-default',
        componentProps: {
          texto: 'Mensagem enviada com sucesso!',
          // icone: '/assets/icons/icon-figa.png',
          cssClass: 'success',
          btnLabel: 'Fechar',
        }
      }
    );
    // modal.onWillDismiss().then((info: any) => {});
    return await modal.present();
  }
}
