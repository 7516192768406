import { Injectable } from '@angular/core';
import { ActivatedRoute, CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { AuthenticationService } from '../services/auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PreAuthGuard implements CanLoad {

  constructor(
    private auth: AuthenticationService,
    private router: Router
  ) {
  }

  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    const isAuthenticated = this.auth.isLogged();
    if (isAuthenticated) {
      const codigoInfluencer = segments[1] ? segments[1].path : null;
      const url = codigoInfluencer ? `/menu/home/${codigoInfluencer}` : `/menu/home`;
      await this.router.navigate([url]);
      return false;
    }
    return true;
  }
}
