import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as moment from 'moment';
import { UserService } from 'src/services/user/user.service';

@Component({
  selector: 'modal-quotes-table',
  templateUrl: 'modal-quotes-table-component.html',
  styleUrls: ['modal-quotes-table-component.scss']
})

export class ModalQuotesTableComponent implements OnInit {

  list: any[] = [
    {
      cod: 'G',
      name: 'Grupo',
      value: '  18',
    },
    {
      cod: 'D',
      name: 'Dezena',
      value: '  60',
    },
    {
      cod: 'C',
      name: 'Centena',
      value: '  600',
    },
    {
      cod: 'M',
      name: 'Milhar',
      value: '  5.000',
    },
    {
      cod: 'DD',
      name: 'Duque de Dezena',
      value: '  300',
    },
    {
      cod: 'DG',
      name: 'Duque de Grupo',
      value: '  21',
    },
    {
      cod: 'TD',
      name: 'Terno de Dezena',
      value: '  3.000',
    },
    {
      cod: 'TG',
      name: 'Terno de Grupo',
      value: '  140',
    },
  ];

  status: any;
  date: any;
  cotada:any = {
    valor: []
  }
  constructor(private modalCtrl: ModalController,
    private navParams: NavParams,
    private user: UserService) {
    this.status = this.navParams.get('status');
  }

  async ngOnInit() {

    this.date = moment().format('DD/MM/YYYY');
    console.log(this.date);

    this.cotada = await this.user.cotadaMilhar();

  }

  getMilhar() {
    return this.cotada.valor.join(', ')

  }
  onClose() {
    this.modalCtrl.dismiss();
  }
}
