import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ModalQuotesTableComponent } from '../modal-quotes-table/modal-quotes-table-component';

@Component({
    selector: 'modal-cotada',
    templateUrl: 'modal-cotada-component.html',
    styleUrls: ['modal-cotada-component.scss']
})

export class ModalCotadaComponent implements OnInit {

    type: any;

    constructor(private modalCtrl: ModalController,
        private navParams: NavParams) {

        this.type = this.navParams.get('type');
    }

    async ngOnInit() {
        console.log('ngOnInit - > ModalCotadaComponent');
    }

    onClose() {
        this.modalCtrl.dismiss();
    }

    onContinue() {
        this.modalCtrl.dismiss(true);
    }

    async onCotada() {
        const modal = await this.modalCtrl.create({
            component: ModalQuotesTableComponent,
            keyboardClose: true,
            cssClass: 'modal-fab-bottom modal-default',
            componentProps: {}
        }
        );

        return await modal.present();
    }

    showMessage(type) {
        switch (type) {
            case 'Milhar':
                return 'com premiação de 500/1';
            case 'Centena':
                return 'com premiação de 100/1';
            case 'Milhar/Centena':
                return 'com premiação de 100/1 ou 500/1';
            default:
                return 'com premiação reduzida';
        }
        // com premiação reduzida
    }
}
