import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import * as moment from 'moment';

@Component({
  selector: 'modal-resume',
  templateUrl: 'modal-resume-component.html',
  styleUrls: ['modal-resume-component.scss']
})

export class ModalResumeComponent implements OnInit {

  @Input() list: any[] = [];
  @Input() status: string;

  constructor(private modalCtrl: ModalController, private alertController: AlertController, private cd: ChangeDetectorRef) {
  }

  async ngOnInit() {
    console.log(this.list);
  }

  getTotal(valor, count) {
    return ((parseInt(valor) * count) / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).replace("R$", "");
  }

  getValue(valor) {
    console.log('valor', valor)
    return ((parseInt(valor)) / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).replace("R$", "");
  }

  // teste(ind) {
  //   debugger
  //
  //   this.list.splice(ind, 1);
  //   console.log(this.list)
  // }

  async deleteItem(item, ind) {
    console.log(ind)
    const alerta = await this.alertController.create({
      header: 'Excluir!',
      message: 'Tem certeza que deseja excluir este jogo?',
      buttons: [
        'Cancelar',
        {
          text: 'Excluir',
          handler: () => {
            this.list.splice(ind, 1);
            this.cd.detectChanges();
          }
        }
      ],
    });

    await alerta.present();
  }

  onClose() {
    this.modalCtrl.dismiss(this.list);
  }
}
