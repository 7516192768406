import { LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { RequestService } from '../api/request.service';
import { AuthenticationService } from '../auth/authentication.service';
import { URL_BASE } from 'src/util/constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: RequestService,
    private loadingCtrl: LoadingController,
    private auth: AuthenticationService
  ) {
  }

  async validaTelefone(params) {
    return await this.http.post(URL_BASE + '/valida-telefone', params);
  }

  async cadastro(params) {
    return await this.http.post(URL_BASE + '/new/user', params);
  }

  async sendEmail(params) {
    return await this.http.post(URL_BASE + '/fale-conosco', params);
  }

  async update(params) {
    return await this.http.post(URL_BASE + '/user', params);
  }

  async validaCpf(params) {
    return await this.http.post(URL_BASE + '/user/valida-cpf', params);
  }

  async transfer(params) {
    return await this.http.post(URL_BASE + '/user/transfer', params);
  }

  async cardAdd(params) {
    return await this.http.post(URL_BASE + '/user/card-add', params);
  }

  async cardList(params) {
    return await this.http.post(URL_BASE + '/user/card-list', params);
  }

  async listBanks(params) {
    return await this.http.post(URL_BASE + '/user/bank-list', params);
  }

  async cardRemove(params) {
    return await this.http.post(URL_BASE + '/user/card-remove', params);
  }

  async pagamento(params) {
    return await this.http.post(URL_BASE + '/pagamento', params);
  }

  async pagamentoDetail(id) {
    return await this.http.get(URL_BASE + '/pagamento/detail?id=' + id, null);
  }

  async gerar(params) {
    return await this.http.post(URL_BASE + '/pagamento/gerarBoleto', params);
  }

  async pix(params) {
    return await this.http.post(URL_BASE + '/pagamento/pix', params);
  }

  async wallet(params) {
    return await this.http.post(URL_BASE + '/pagamento/wallet', params);
  }

  async lottery(params) {
    return await this.http.post(URL_BASE + '/pagamento/lottery', params);
  }

  async saldo(id) {
    return await this.http.get(URL_BASE + '/user/saldo?id=' + id);
  }

  async validaCodigo(params) {
    return await this.http.post(URL_BASE + '/user/valida-codigo', params);
  }

  async validaRevenda(params) {
    console.log(await this.http.post(URL_BASE + '/valida-revenda', {cod: params}));
    return await this.http.post(URL_BASE + '/valida-revenda', {cod: params});
  }

  async changePassword(params) {
    return await this.http.post(URL_BASE + '/user/alterar-senha', params);
  }

  async salvaCodigo(params) {
    return await this.http.post(URL_BASE + '/user/salva-codigo', params);
  }

  async transferencias(params, page = 1, size = 20) {
    return await this.http.get(URL_BASE + '/transferencia/list/' + params._id, { page, size });
  }

  async pagamentos(params) {
    return await this.http.get(URL_BASE + '/pagamento/list_user');
  }

  async resend(params) {
    return await this.http.post(URL_BASE + '/send-code', params);
  }

  async resetPass(params) {
    return await this.http.post(URL_BASE + '/reset-senha', params);
  }

  async validaCode(params) {
    return await this.http.post(URL_BASE + '/valida-code', params);
  }

  async cotadaMilhar() {
    return await this.http.get(URL_BASE + '/cotada-milhar');
  }

  async valorSaque() {
    return await this.http.get(URL_BASE + '/user/valor-saque');
  }

  async pegaEndereco(cep) {
    return await this.http.get(URL_BASE + '/user/cep?cep=' + cep);
  }

  async loginDirect(params) {
    const result: any = await this.http.post(URL_BASE + '/authenticate', params);
    if (result && result.token && result.user) {
      await this.auth.setUserData(result.user);
      await this.auth.resetAuthorizationToken(result.token);
      return true;
    }
    return false;
  }

  async sendSMS(params) {
    return await this.http.post(URL_BASE + '/send-sms', params);
  }


  async validaSMS(params) {
    return await this.http.post(URL_BASE + '/valida-code-sms', params);
  }

  async delete() {
    return await this.http.post(URL_BASE + '/user/delete');
  }
}
