import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, LoadingController, ToastController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Util } from "../../../util/utils";
import { UserService } from 'src/services/user/user.service';

@Component({
  selector: 'modal-deposito',
  templateUrl: 'modal-deposito-component.html',
  styleUrls: ['modal-deposito-component.scss']
})

export class ModalDepositoComponent implements OnInit {

  public formGroup = new FormGroup({
    numCard: new FormControl('', {
      validators: [
        Validators.required,
      ]
    }),
    validityCard: new FormControl('', {
      validators: [
        Validators.required,
      ]
    }),
    nameCard: new FormControl('', {
      validators: [
        Validators.required,
      ]
    }),
    cvv: new FormControl('', {
      validators: [
        Validators.required,
      ]
    })
  });

  titulo: any;
  cartao: any;
  banco: any;
  code_promo: any;
  valor: any;

  constructor(private modalCtrl: ModalController,
    private navParams: NavParams,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private userService: UserService) {
    this.cartao = this.navParams.get('cartao');
    this.banco = this.navParams.get('banco');
    this.valor = this.navParams.get('valor');
    this.code_promo = this.navParams.get('code_promo');

    console.log(this.banco)
    console.log(this.valor)
  }

  async ngOnInit() {
    console.log('ngOnInit - > ModalDepositoComponent');
  }

  getTextFormat(text) {
    return Util.getTextFormat(text);
  }

  onClose() {
    this.modalCtrl.dismiss(false);
  }

  async confirmar() {

    const loading = await this.loadingCtrl.create();
    await loading.present();

    const form = {
      cartao: this.cartao,
      banco: this.banco,
      valor: this.valor,
      code_promo: this.code_promo
    };
    const user: any = await this.userService.pagamento(form);
    if ((user && user._id) || user.validate === true) {
      if (user.validate === true) {
        const toast = await this.toastCtrl.create({
          message: user.msg,
          position: 'top',
          duration: 3000
        });
        await toast.present();
      }
      await loading.dismiss(true);
      this.modalCtrl.dismiss(true);
    } else {
      await loading.dismiss(true);
      const toast = await this.toastCtrl.create({
        message: 'Não autorizada!',
        position: 'top',
        duration: 3000
      });
      await toast.present();
    }
  }

  getValue(valor) {
    return (valor / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).replace("R$", "");
  }
}
