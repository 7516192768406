import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'modal-term',
    templateUrl: 'modal-term-component.html',
    styleUrls: ['modal-term-component.scss']
})

export class ModalTermComponent implements OnInit {

    form = new FormGroup({
        name: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        email: new FormControl('', {
            validators: [
                Validators.required, Validators.email,
            ]
        }),
        message: new FormControl('', {
            validators: [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(1400),
            ]
        })
    });

    status: any;

    constructor(private modalCtrl: ModalController,
                private fb: FormBuilder,
                private navParams: NavParams) {
        this.status = this.navParams.get('status');
    }

    async ngOnInit() {
    }

    onSend() {
        this.modalCtrl.dismiss('send');
    }

    onClose() {
        this.modalCtrl.dismiss();
    }
}
