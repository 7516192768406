import { NgModule } from '@angular/core';
import { CapitalizedDirective } from './capitalized/capitalized.directive';
import { OnlyNumbersDirective } from './only-numbers.directive';

@NgModule({
  declarations: [
    CapitalizedDirective,
    OnlyNumbersDirective,
  ],
  imports: [],
  exports: [
    CapitalizedDirective,
    OnlyNumbersDirective
  ],
})
export class DirectivesModule {
}
