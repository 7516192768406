import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private httpWeb: HttpClient) {
  }

  async get(path: string, params?) {
    return await this.httpWeb.get(path, {params}).toPromise();
  }

  async post(path: string, body?) {
    return await this.httpWeb.post(path, body).toPromise();
  }

  async put(path: string, body, json?: boolean) {
      return await this.httpWeb.put(path, body).toPromise();
  }

  async delete(path: string, body) {
      return await this.httpWeb.delete(path, body).toPromise();
  }

}
