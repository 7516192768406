import { Injectable } from '@angular/core';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';

@Injectable()
export class UtilService {

  private loading: any;
  public cordova = false;
  public widthPlatform: any;
  public heightPlatform: any;
  private maxWidth = 640;

  constructor(
    private platform: Platform,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController
  ) {
    this.cordova = this.platform.is('cordova');
    platform.ready().then(() => {
      this.widthPlatform = platform.width() <= this.maxWidth ? platform.width() : this.maxWidth;
      this.heightPlatform = platform.height();
    });
  }

  async onLoading(message: string = '') {
    this.loading = await this.loadingCtrl.create({
      message
    });
    return await this.loading.present();
  }

  async endLoading() {
    await this.loading.dismiss().catch((error) => {
      console.log(error);
      this.loading.dismissAll();
    });
  }

  async alert(title, message, buttons) {
    const alert = await this.alertCtrl.create({
      message,
      buttons
    });
    await alert.present();
  }

  async toast(message: string, duration = 6000) {
    const toast = await this.toastCtrl.create({
      message,
      position: 'top',
      duration,
      color: 'dark'
    });
    toast.present();
  }

  formatarCEP(cep) {
    const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;
    return cep.replace(re, '$1.$2-$3');
  }

  formatarCpfCnpj(valor) {
    if (!valor) {
      return '';
    }
    if (valor.length === 11) {
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
    }
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
  }

  removerFormatacao(texto) {
    return texto.replace(/[^\w\s]/gi, '');
  }

  validadata(dataInput) {
    let data = dataInput; // pega o valor do input
    data = data.replace(/\//g, '-'); // substitui eventuais barras (ex. IE) "/" por hífen "-"
    const data_array = data.split('-'); // quebra a data em array

    // para o IE onde será inserido no formato dd/MM/yyyy
    if (data_array[0].length != 4) {
      data = data_array[2] + '-' + data_array[1] + '-' + data_array[0]; // remonto a data no formato yyyy/MM/dd
    }

    // comparo as datas e calculo a idade
    const hoje = new Date();
    const nasc = new Date(data);
    let idade = hoje.getFullYear() - nasc.getFullYear();
    const m = hoje.getMonth() - nasc.getMonth();
    if (m < 0 || (m === 0 && hoje.getDate() < nasc.getDate())) { idade--; }

    if (idade < 18) {
      return false;
    }

    if (idade >= 18 && idade <= 120) {
      return true;
    }

    // se for maior que 60 não vai acontecer nada!
    return false;
  }

  async validcpf(cpf) {
    cpf = cpf.replace(/\D/g, '');
    if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
      return false;
    }
    let result = true;
    [9, 10].forEach((j) => {
      let soma = 0, r;
      cpf.split(/(?=)/).splice(0, j).forEach((e, i) => {
        soma += Number(e) * ((j + 2) - (i + 1));
      });
      r = soma % 11;
      r = (r < 2) ? 0 : 11 - r;
      if (r !== Number(cpf.substring(j, j + 1))) {
        result = false;
      }
    });
    return result;
  }

  validaData(dataentrada) {
    const patternData = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
    return patternData.test(dataentrada);
  }

  validaDataAtual(dataentrada) {
    const partesData = dataentrada.split('/');
    const data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
    return data <= new Date();
  }

  validPass(pass) {
    if (!/[a-z]/gm.test(pass)) {
      return false;
    }

    if (!/[A-Z]/gm.test(pass)) {
      return false;
    }
    if (!/[!@#$%*()_+^&{}}:;?.]/gm.test(pass)) {
      return false;
    }

    return pass.length > 8;
  }

  validaEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
}
