import { Injectable } from '@angular/core';
import { RequestService } from '../api/request.service';
import { AuthenticationService } from '../auth/authentication.service';
import { URL_BASE } from 'src/util/constants';

@Injectable({
    providedIn: 'root'
})
export class RevendaService {

    constructor(
        private http: RequestService,
        private auth: AuthenticationService
    ) {}

    async show(params) {
      return await this.http.post(URL_BASE + '/show/influencer', params);
    }

    async associarCupom(params) {
        return await this.http.post(URL_BASE + '/salvar/cupom', params);
    }

    async cupomUser(params) {
        return await this.http.get(URL_BASE + '/cupom/userPrimeiraRecarga', params);
    }

}
