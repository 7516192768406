import {ModalController} from '@ionic/angular';
import {Component, Input, OnInit} from '@angular/core';


@Component({
    selector: 'app-ganhadores-modal',
    templateUrl: 'ganhadores-modal-component.html',
    styleUrls: ['ganhadores-modal-component.scss']
})
export class GanhadoresModalComponent implements OnInit {

    constructor(
        private modalController: ModalController,
        private modalCtrl: ModalController
    ) {
    }

    @Input() resultado: any;

    async ngOnInit() {
    }

    dismissModal(data: boolean) {
        this.modalController.dismiss(data);
    }

    onClose() {
        this.modalCtrl.dismiss();
    }

}
