import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-calendario',
  templateUrl: './modal-calendario.component.html',
  styleUrls: ['./modal-calendario.component.scss'],
})
export class ModalCalendarioComponent implements OnInit {

  filter: any;

  constructor(private modalCtrl: ModalController) {
  }

  ngOnInit() {
  }

  async onSelectedDayCalendar(filter) {
    this.filter = filter;
    this.modalCtrl.dismiss(filter);
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
