import { Directive, HostListener, Input, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[capitalized]'
})
export class CapitalizedDirective {
  @Input() public capitalized: FormControl;

  constructor() {
  }

  @HostListener('focusout') onFocusOut() {
    const capitalizedPhrase = [];

    this.capitalized.value.split(' ').forEach(value => {
      if (value[0]) {
        capitalizedPhrase.push(value[0].toUpperCase() + value.substr(1).toLowerCase());
      } else {
        capitalizedPhrase.push('');
      }
    });

    this.capitalized.patchValue(capitalizedPhrase.join(' '));
  }
}
