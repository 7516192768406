import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CalendarComponentOptions } from 'ion4-calendar';
import * as moment from 'moment';
import { debounce } from "rxjs/operators";

@Component({
  selector: 'calendar-component',
  templateUrl: 'calendar-component.html',
  styleUrls: ['./calendar-component.scss']
})

export class CalendarComponent implements AfterViewInit {
  @ViewChild('compCalendar', {static: false}) compCalendar: ElementRef;

  @Input() tipo: string;
  @Input() pickMode = 'single';
  @Input() selected: Date;
  @Input() classCSS: string;
  @Output() selectedDateEvent = new EventEmitter();

  daySelected = moment();
  dateShow: any;
  dateRange: { from: string; to: string; };

  optionsDay: CalendarComponentOptions = {
    // from: new Date(2020, 6, 22),
    to: moment().add(7, 'days').toDate(),
    pickMode: 'single',
    monthPickerFormat: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    weekdays: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthFormat: 'MMMM/YYYY',
  };

  optionsRange: CalendarComponentOptions = {
    from: moment().subtract((moment().get('year') - 1970) * 12, 'months').toDate(),
    pickMode: 'range',
    monthPickerFormat: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    weekdays: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthFormat: 'MMMM/YYYY',
  };

  currentDate = moment();
  year: any;
  day: any;
  month: any;
  calendarMaximum: any;

  constructor() {
    moment.locale('pt-br');
  }

  ionViewDidLeave() {
    console.log(`ionViewDidLeave: `, this.selected)
  }

  ngAfterViewInit() {
    console.log(this.selected);
    if (this.pickMode === 'single' && this.selected && moment(this.selected).isValid()) {
      this.daySelected = moment(this.selected);
    }

    this.year = this.currentDate.year();
    this.month = this.currentDate.month();
    this.day = this.currentDate.date();

    const hour = moment(moment().format('HH:mm'), 'HH:mm a');

    if (hour.isAfter(moment('20:50', 'HH:mm a'))) {
      const newDate = moment().add(1, 'days');
      this.month = newDate.month();
      this.day = newDate.date();
    }

    this.calendarMaximum = moment().subtract(7, 'year').toDate();

    this.optionsDay.from = new Date(this.year, this.month, this.day);
  }

  onFilterDate() {
    console.log(this.selected)
    this.selectedDateEvent.emit({
      dateShow: this.dateShow,
      dateRange: this.dateRange,
    });
  }

  onChange(day) {
    console.log(day)
    if (this.dateRange) {
      // @ts-ignore
      this.dateShow = this.dateRange.from.format('DD/MM/YYYY').toLocaleString() + ' até ' + this.dateRange.to.format('DD/MM/YYYY').toLocaleString();
    }
    if (this.pickMode === 'single') {
      this.selectedDateEvent.emit(day);
    }
  }
}
