import { ModalQuotesTableComponent } from './../modal-quotes-table/modal-quotes-table-component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, NavParams } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'modal-aprender-detalhe',
  templateUrl: 'modal-aprender-detalhe-component.html',
  styleUrls: ['modal-aprender-detalhe-component.scss']
})

export class ModalAprenderDetalheComponent implements OnInit {

  detatlhe: any;

  constructor(private modalCtrl: ModalController,
              private navParams: NavParams) {
    this.detatlhe = navParams.get('detalhe');
  }

  async ngOnInit() {
    console.log('ngOnInit - > ModalAprenderDetalheComponent');
  }

  onClose() {
    this.modalCtrl.dismiss();
  }

  async openTable() {
    const modal = await this.modalCtrl.create({
        component: ModalQuotesTableComponent,
        keyboardClose: true,
        cssClass: 'modal-fab-bottom modal-default',
        componentProps: {}
      }
    );
    return await modal.present();
  }
}
