import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tipo-jogo-component',
  templateUrl: 'tipo-jogo-component.html',
  styleUrls: ['./tipo-jogo-component.scss']
})

export class TipoJogoComponent implements OnInit {

  @Output() onChangeValue = new EventEmitter();

  @Input() classCSS: string;
  @Input() defaultValue: any;

  listTypes: any[] = [
    'Grupo',
    'Dezena',
    'Centena',
    'Milhar',
    'Duque de dezenas',
    'Duque de grupo',
    'Terno de dezenas',
    'Terno de grupo',
    'Milhar/Centena',
  ];

  typeSelected: any;

  constructor() {

  }

  ngOnInit() {
  }

  onSelectType(type) {
    this.typeSelected = type;
    const detail = {
      value: type
    };

    this.onChangeValue.emit({
      value: type
    });
  }
}
