import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  DoCheck,
  ChangeDetectorRef,
} from '@angular/core';
import * as moment from 'moment';
import { Util } from "../../../util/utils";

@Component({
  selector: 'list-results-component',
  templateUrl: 'list-results-component.html',
  styleUrls: ['./list-results-component.scss']
})

export class ListResultsComponent implements OnInit, DoCheck {


  groupsList = [
    {
      id: '01',
      nome: 'Avestruz',
      numeros: ['01', '02', '03', '04'],
    },
    {
      id: '02',
      nome: 'Águia',
      numeros: ['05', '06', '07', '08'],
    },
    {
      id: '03',
      nome: 'Burro',
      numeros: ['09', '10', '11', '12'],
    },
    {
      id: '04',
      nome: 'Borboleta',
      numeros: ['13', '14', '15', '16'],
    },
    {
      id: '05',
      nome: 'Cachorro',
      numeros: ['17', '18', '19', '20'],
    },
    {
      id: '06',
      nome: 'Cabra',
      numeros: ['21', '22', '23', '24'],
    },
    {
      id: '07',
      nome: 'Carneiro',
      numeros: ['25', '26', '27', '28'],
    },
    {
      id: '08',
      nome: 'Camelo',
      numeros: ['29', '30', '31', '32'],
    },
    {
      id: '09',
      nome: 'Cobra',
      numeros: ['33', '34', '35', '36'],
    },
    {
      id: '10',
      nome: 'Coelho',
      numeros: ['37', '38', '39', '40'],
    },
    {
      id: '11',
      nome: 'Cavalo',
      numeros: ['41', '42', '43', '44'],
    },
    {
      id: '12',
      nome: 'Elefante',
      numeros: ['45', '46', '47', '48'],
    },
    {
      id: '13',
      nome: 'Galo',
      numeros: ['49', '50', '51', '52'],
    },
    {
      id: '14',
      nome: 'Gato',
      numeros: ['53', '54', '55', '56'],
    },
    {
      id: '15',
      nome: 'Jacaré',
      numeros: ['57', '58', '59', '60'],
    },
    {
      id: '16',
      nome: 'Leão',
      numeros: ['61', '62', '63', '64'],
    },
    {
      id: '17',
      nome: 'Macaco',
      numeros: ['65', '66', '67', '68'],
    },
    {
      id: '18',
      nome: 'Porco',
      numeros: ['69', '70', '71', '72'],
    },
    {
      id: '19',
      nome: 'Pavão',
      numeros: ['73', '74', '75', '76'],
    },
    {
      id: '20',
      nome: 'Peru',
      numeros: ['77', '78', '79', '80'],
    },
    {
      id: '21',
      nome: 'Touro',
      numeros: ['81', '82', '83', '84'],
    },
    {
      id: '22',
      nome: 'Tigre',
      numeros: ['85', '86', '87', '88'],
    },
    {
      id: '23',
      nome: 'Urso',
      numeros: ['89', '90', '91', '92'],
    },
    {
      id: '24',
      nome: 'Veado',
      numeros: ['93', '94', '95', '96'],
    },
    {
      id: '25',
      nome: 'Vaca',
      numeros: ['97', '98', '99', '00'],
    },
  ];

  @Output() onDetalheAtendinento = new EventEmitter();
  @Input() classCSS: string;
  @Input() list: any[] = [];
  @Input() detalhe: any = {};
  @Input() filter: any;
  @Output() onPage = new EventEmitter();
  daySelected: any = {};

  constructor(private cd: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.list = this.list.slice();
    this.onSelectDay(this.list[0]);
  }

  async infinit(evt) {
    this.onPage.emit({});
    evt.target.complete();
  }

  getGroup(num, type) {
    let item;

    if (num.trim().length === 3) {
      num = num.trim().substr(1, num.trim().length); // 98
    } else {
      num = num.trim().substr(2, num.trim().length); // 98
    }

    for (const key of this.groupsList) {
      const ch = key.numeros.find((data) => {
        return data === num;
      });
      if (ch) {
        item = key;
        break;
      }
    }

    if (type === 'id') {
      if (item && item.id) {
        return item.id;
      }
    } else {
      if (item && item.nome) {
        return Util.removeAcentos(item.nome.toLowerCase());
      }
    }
    // this.cd.detectChanges();
  }

  ngDoCheck() {
    if (!this.list.length) {
      this.daySelected = null;
    }
  }

  dayFormat(day) {
    return moment(day).format('DD/MM');
  }

  onSelectDay(item) {
    this.daySelected = item;
  }

  showHours(hour) {
    switch (hour) {
      case 'PTM': {
        return '11h (PTM)';
      }
      case 'PT': {
        return '14h (PT)';
      }
      case 'PTV': {
        return '16h (PTV)';
      }
      case 'PTN': {
        return '18h (PTN)';
      }
      case 'COR': {
        return '21h (COR)';
      }
      case 'FED': {
        return '18h30 (FED)';
      }
    }
  }
}
