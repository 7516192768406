import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { ComponentsModule } from './components/components.module';
import { ModalComponents } from './modal-components/modal-component.module';
import { IonicStorageModule } from '@ionic/storage';
import { BrMaskerModule } from 'br-mask';
import { DirectivesModule } from './directives/directives.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StorageService } from 'src/services/storage/storage';
import { UtilService } from 'src/util/util.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { QrCodeModule } from 'ng-qrcode';
import { AuthorizationInterceptor } from '../interceptors/authorization.interceptor';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {RecaptchaModule} from "ng-recaptcha";

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    DirectivesModule,
    BrMaskerModule,
    QrCodeModule,
    BrowserModule,
    RecaptchaModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    ComponentsModule,
    HttpClientModule,
    ModalComponents,
  ],
  providers: [
    StatusBar,
    StorageService,
    UtilService,
    InAppBrowser,
    SocialSharing,
    MobileAccessibility,
    Clipboard,
    SplashScreen,
    VideoPlayer,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    }
  ],
  exports: [
    DirectivesModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
