import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { UserService } from 'src/services/user/user.service';

@Component({
  selector: 'modal-pix',
  templateUrl: 'modal-pix-component.html',
  styleUrls: ['modal-pix-component.scss']
})

export class ModalPixComponent implements OnInit {

  @Input() pix: any;
  refreshIntervalId;

  @Input() totalValue: any;

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private userService: UserService,
  ) { }

  ngOnInit() {
    const TIME_IN_MS = 10000;
    this.refreshIntervalId = setInterval(async () => {
      const pagamento: any = await this.userService.pagamentoDetail(this.pix.id);
      if (pagamento.status !== 'PENDENTE') {
        clearInterval(this.refreshIntervalId);
        this.modalCtrl.dismiss(pagamento.status === 'APROVADO');
      } else if (pagamento.status === 'CANCELADO' || pagamento.status === 'NÃO AUTORIZADO') {
        const toast = await this.toastCtrl.create({
          message: 'O QR CODE / PIX foi expirado.',
          position: 'top',
          duration: 3000
        });
        console.log(this.pix);
        return await toast.present();
      }
    }, TIME_IN_MS);
  }

  ngOnDestroy() {
    clearInterval(this.refreshIntervalId);
  }

  async onClose() {
    const pagamento: any = await this.userService.pagamentoDetail(this.pix.id);
    clearInterval(this.refreshIntervalId);
    this.modalCtrl.dismiss(pagamento.status === 'APROVADO');
  }

  async copiaECola(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    const toast = await this.toastCtrl.create({
      message: 'Código do QRCODE copiado com sucesso.',
      position: 'top',
      duration: 3000
    });
    return await toast.present();
  }
}
