import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import * as moment from 'moment';
import { Premio } from 'src/services/rifas/mock';

@Component({
  selector: 'raffle-numbers',
  templateUrl: 'modal-raffle-numbers-component.html',
  styleUrls: ['modal-raffle-numbers-component.scss']
})

export class ModalRaffleNumbers implements OnInit {
  imagemPule = '/assets/imgs/img-pule.svg';

  @Input() list: any;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  async ngOnInit() {
  }

  getFormattedDate(date: Date): string {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  onClose() {
    this.modalCtrl.dismiss();
  }

}
