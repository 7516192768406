import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams, LoadingController, ToastController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/services/user/user.service';

@Component({
    selector: 'modal-contact',
    templateUrl: 'modal-contact-component.html',
    styleUrls: ['modal-contact-component.scss']
})

export class ModalContactComponent implements OnInit {

    form = new FormGroup({
        name: new FormControl('', {
            validators: [
                Validators.required,
            ]
        }),
        email: new FormControl('', {
            validators: [
                Validators.required, Validators.email,
            ]
        }),
        message: new FormControl('', {
            validators: [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(1400),
            ]
        })
    });

    status: any;

    constructor(private modalCtrl: ModalController,
                private fb: FormBuilder,
                private navParams: NavParams,
                private loadingCtrl: LoadingController,
                private userService: UserService,
                private toastCtrl: ToastController) {
        this.status = this.navParams.get('status');
    }

    async ngOnInit() {
    }

    async onSend() {
        const loading = await this.loadingCtrl.create();
        await loading.present();

        let form = {
            message: this.form.value.message,
            name: this.form.value.name,
            email: this.form.value.email,
        }
        const user: any = await this.userService.sendEmail(form);
        if (user && user.validate.success == true) {
            await loading.dismiss();
            this.modalCtrl.dismiss('send');
        } else {
            await loading.dismiss();
            const toast = await this.toastCtrl.create({
                message: 'Ocorreu um erro ao enviar seu contato!',
                position: 'top',
                duration: 3000
            });
            await toast.present();
        }
    }

    onClose() {
        this.modalCtrl.dismiss();
    }

    changeInput(ev) {
        const words = ev.target.value.split(" ");

        const t = words.map((word) => { 
            return word && word[0].toUpperCase() + word.substring(1); 
        }).join(" ");

        this.form.get('name').setValue(t);
    } 
}
