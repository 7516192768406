import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, NavParams } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'modal-login',
    templateUrl: 'modal-login-component.html',
    styleUrls: ['modal-login-component.scss']
})

export class ModalLoginComponent implements OnInit {

    public formGroup = new FormGroup({
        email: new FormControl('', {
            validators: [
                Validators.required, Validators.email
            ]
        }),
        password: new FormControl('', {
            validators: [
                Validators.required,
            ]
        })
    });

    status: any;

    constructor(private modalCtrl: ModalController,
                private navParams: NavParams) {
        this.status = this.navParams.get('status');
    }

    async ngOnInit() {
        console.log('ngOnInit - > ModalLoginComponent');
    }

    onForgotPassword() {

    }

    onLogin() {
        this.modalCtrl.dismiss('login');
    }

    onClose() {
        this.modalCtrl.dismiss();
    }
}
