import { ModalCotadaComponent } from './modal-cotada/modal-cotada-component';
import { NgModule } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrMaskerModule } from 'br-mask';
import { DirectivesModule } from '../directives/directives.module';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from '../components/components.module';
import { ModalLoginComponent } from './modal-login/modal-login-component';
import { ModalTipoApostaComponent } from './modal-tipo-aposta/modal-tipo-aposta-component';
import { ModalSelecionarDiaComponent } from './modal-selecionar-dia/modal-selecionar-dia-component';
import { ModalAprenderDetalheComponent } from './modal-aprender-detalhe/modal-aprender-detalhe-component';
import { ModalDuvidaDetalheComponent } from './modal-duvida-detalhe/modal-duvida-detalhe-component';
import { ModalPuleComponent } from './modal-pule/modal-pule-component';
import { ModalTransferenciaComponent } from './modal-transferencia/modal-transferencia-component';
import { ModalDefaultComponent } from './modal-default/modal-default-component';
import { ModalLotteryComponent } from './modal-lottery/modal-lottery-component';
import { ModalJogoCadastroComponent } from './modal-jogo-cadastro/modal-jogo-cadastro-component';
import { ModalCadastrarCartaoComponent } from './modal-cadastrar-cartao/modal-cadastrar-cartao-component';
import { ModalDepositoComponent } from './modal-deposito/modal-deposito-component';
import { ModalContactComponent } from './modal-contact/modal-contact-component';
import { ModalQuotesTableComponent } from "./modal-quotes-table/modal-quotes-table-component";
import { ModalResumeComponent } from "./modal-resume/modal-resume-component";
import { ModalTermComponent } from './modal-term/modal-term-component';
import { ModalCalendarioComponent } from "./modal-calendario/modal-calendario.component";
import { ModalPixComponent } from 'src/app/modal-components/modal-pix/modal-pix-component';
import { QrCodeModule } from 'ng-qrcode';
import { ModalAssociarCupomComponent } from 'src/app/modal-components/modal-associar-cupom/modal-associar-cupom-component';
import { ModalRaffleNumbers } from './modal-raffle-numbers/modal-raffle-numbers-component';
import { BuyModalComponent } from './buy-modal-component/buy-modal-component';
import { ModalBetTypeComponent } from './modal-bet-type/modal-bet-type-component';
import {GanhadoresModalComponent} from "./ganharores-modal-component/ganhadores-modal-component";
import {RecaptchaModule} from "ng-recaptcha";

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ComponentsModule,
        ReactiveFormsModule,
        BrMaskerModule,
        QrCodeModule,
        DirectivesModule,
        RouterModule,
        RecaptchaModule,
    ],
  declarations: [
    ModalLoginComponent,
    ModalTipoApostaComponent,
    ModalSelecionarDiaComponent,
    ModalAprenderDetalheComponent,
    ModalDuvidaDetalheComponent,
    ModalPuleComponent,
    ModalTransferenciaComponent,
    ModalDefaultComponent,
    ModalLotteryComponent,
    ModalJogoCadastroComponent,
    ModalCadastrarCartaoComponent,
    ModalAssociarCupomComponent,
    ModalDepositoComponent,
    ModalContactComponent,
    ModalQuotesTableComponent,
    ModalResumeComponent,
    ModalTermComponent,
    ModalCalendarioComponent,
    ModalCotadaComponent,
    ModalPixComponent,
    BuyModalComponent,
    ModalRaffleNumbers,
    ModalBetTypeComponent,
    GanhadoresModalComponent
  ],
  entryComponents: [
    ModalLoginComponent,
    ModalTipoApostaComponent,
    ModalSelecionarDiaComponent,
    ModalAprenderDetalheComponent,
    ModalDuvidaDetalheComponent,
    ModalPuleComponent,
    ModalTransferenciaComponent,
    ModalDefaultComponent,
    ModalLotteryComponent,
    ModalJogoCadastroComponent,
    ModalCadastrarCartaoComponent,
    ModalDepositoComponent,
    ModalContactComponent,
    ModalQuotesTableComponent,
    ModalAssociarCupomComponent,
    ModalResumeComponent,
    ModalTermComponent,
    ModalCalendarioComponent,
    ModalCotadaComponent,
    ModalPixComponent,
    BuyModalComponent,
    ModalRaffleNumbers,
    ModalBetTypeComponent,
    GanhadoresModalComponent
  ],
  exports: [
    ModalLoginComponent,
    ModalTipoApostaComponent,
    ModalSelecionarDiaComponent,
    ModalAprenderDetalheComponent,
    ModalDuvidaDetalheComponent,
    ModalPuleComponent,
    ModalTransferenciaComponent,
    ModalDefaultComponent,
    ModalJogoCadastroComponent,
    ModalCadastrarCartaoComponent,
    ModalAssociarCupomComponent,
    ModalDepositoComponent,
    ModalContactComponent,
    ModalQuotesTableComponent,
    ModalResumeComponent,
    ModalTermComponent,
    ModalCalendarioComponent,
    ModalCotadaComponent,
    ModalPixComponent,
    BuyModalComponent,
    ModalRaffleNumbers,
    ModalBetTypeComponent,
    GanhadoresModalComponent
  ]
})

export class ModalComponents {
}
