import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { Util } from '../../../util/utils';

@Component({
  selector: 'animal-slides-component',
  templateUrl: 'animal-slides-component.html',
  styleUrls: ['./animal-slides-component.scss']
})

export class AnimalSlidesComponent implements OnInit {

  @ViewChild('slider', {static: true}) slider: IonSlides;

  @Output() onSelectSlide = new EventEmitter();

  @Input() classCSS: string;

  slidesParams = {
    spaceBetween: 0,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
  };

  slidesOpts: any[] = [];
  activeSlide = 0;

  constructor() {

  }


  slidePrev() {
    this.slider.slidePrev();
  }
  slideNext() {
    this.slider.slideNext();
  }

  ngOnInit() {
    this.getSlidesOpts();
    this.slider.ionSlideDidChange.subscribe(async () => {
      this.activeSlide = await this.slider.getActiveIndex();
      this.onSelectSlide.emit({
        slide: this.slidesOpts[this.activeSlide]
      });
    });
  }

  formatTypeIcon(type, active) {
    if (active) {
      return Util.removeAcentos(type + '-branco')+'.svg';
    }

    return Util.removeAcentos(type)+'-preto.png';
  }

  getSlidesOpts() {
    return this.slidesOpts = [
      {
        id: '01',
        nome: 'Avestruz',
        numeros: ['01', '02', '03', '04'],
      },
      {
        id: '02',
        nome: 'Águia',
        numeros: ['05', '06', '07', '08'],
      },
      {
        id: '03',
        nome: 'Burro',
        numeros: ['09', '10', '11', '12'],
      },
      {
        id: '04',
        nome: 'Borboleta',
        numeros: ['13', '14', '15', '16'],
      },
      {
        id: '05',
        nome: 'Cachorro',
        numeros: ['17', '18', '19', '20'],
      },
      {
        id: '06',
        nome: 'Cabra',
        numeros: ['21', '22', '23', '24'],
      },
      {
        id: '07',
        nome: 'Carneiro',
        numeros: ['25', '26', '27', '28'],
      },
      {
        id: '08',
        nome: 'Camelo',
        numeros: ['29', '30', '31', '32'],
      },
      {
        id: '09',
        nome: 'Cobra',
        numeros: ['33', '34', '35', '36'],
      },
      {
        id: '10',
        nome: 'Coelho',
        numeros: ['37', '38', '39', '40'],
      },
      {
        id: '11',
        nome: 'Cavalo',
        numeros: ['41', '42', '43', '44'],
      },
      {
        id: '12',
        nome: 'Elefante',
        numeros: ['45', '46', '47', '48'],
      },
      {
        id: '13',
        nome: 'Galo',
        numeros: ['49', '50', '51', '52'],
      },
      {
        id: '14',
        nome: 'Gato',
        numeros: ['53', '54', '55', '56'],
      },
      {
        id: '15',
        nome: 'Jacaré',
        numeros: ['57', '58', '59', '60'],
      },
      {
        id: '16',
        nome: 'Leão',
        numeros: ['61', '62', '63', '64'],
      },
      {
        id: '17',
        nome: 'Macaco',
        numeros: ['65', '66', '67', '68'],
      },
      {
        id: '18',
        nome: 'Porco',
        numeros: ['69', '70', '71', '72'],
      },
      {
        id: '19',
        nome: 'Pavão',
        numeros: ['73', '74', '75', '76'],
      },
      {
        id: '20',
        nome: 'Peru',
        numeros: ['77', '78', '79', '80'],
      },
      {
        id: '21',
        nome: 'Touro',
        numeros: ['81', '82', '83', '84'],
      },
      {
        id: '22',
        nome: 'Tigre',
        numeros: ['85', '86', '87', '88'],
      },
      {
        id: '23',
        nome: 'Urso',
        numeros: ['89', '90', '91', '92'],
      },
      {
        id: '24',
        nome: 'Veado',
        numeros: ['93', '94', '95', '96'],
      },
      {
        id: '25',
        nome: 'Vaca',
        numeros: ['97', '98', '99', '00'],
      },
    ];
  }
}
