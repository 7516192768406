import { ToastController, NavController } from '@ionic/angular';
import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';
import { UserService } from 'src/services/user/user.service';
import { LoadingController, ModalController } from '@ionic/angular';
import { ModalPixComponent } from '../modal-pix/modal-pix-component';
import { AuthenticationService } from 'src/services/auth/authentication.service';
import { CotasService } from 'src/services/user/cotas.service';
import { ApostaService } from 'src/services/user/aposta.service';
import { ModalDefaultComponent } from '../modal-default/modal-default-component';
import { ModalPuleComponent } from '../modal-pule/modal-pule-component';
import { Storage } from '@ionic/storage';
import {SITE_KEY} from "../../../util/constants";

@Component({
    selector: 'app-buy-modal',
    templateUrl: 'buy-modal-component.html',
    styleUrls: ['buy-modal-component.scss']
})
export class BuyModalComponent implements OnInit {

    constructor(
        private modalController: ModalController,
        private loadingCtrl: LoadingController,
        private auth: AuthenticationService,
        private cotasService: CotasService,
        private apostaService: ApostaService,
        private toastCtrl: ToastController,
        private navCtrl: NavController,
        private storage: Storage,
        private user: UserService,
    ) {
    }
    SITE_KEY = SITE_KEY;
    captcha = '';
    @Input() award: any;
    @Input() quotes: number;
    @Input() purchaseValue: number;
    @Input() tipoJogo: string;
    @Input() infoGame: any;
    @Output() debitEvent = new EventEmitter<any>();

    carteira: any;
    saldo: any;
    isPurchaseInProgress = false;
    isDebitInProgress = false;
    selectedOption = 'pix';

    async ngOnInit() {
        await this.verificaSaldo();
    }

    async verificaSaldo() {
        try {
            const info = await this.auth.getUserData();
            const carteira: any = await this.user.saldo(info._id);

            let saldo = carteira && carteira.saldo ? carteira.saldo : 0;
            const saldoBonus = carteira && carteira.saldo_bonus ? carteira.saldo_bonus : 0;

            saldo += carteira && carteira.saldo_premio ? carteira.saldo_premio : 0;
            this.saldo = saldo + saldoBonus;
        } catch (err) {
            console.error(err);
        }
    }

    formatValue(valor) {
        return (valor / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '');
    }

    selectOption(option: string) {
        this.selectedOption = option;
    }

    cancel() {
        this.dismissModal(false);
    }

    activateDoneForm() {
        this.modalController.dismiss({ wallet: true, captcha: this.captcha });
    }

    activePixModal() {
        this.modalController.dismiss({ pix: true });
    }

    confirm() {
        if (this.selectedOption === 'saldo' && this.tipoJogo === 'tradicional') {
            this.activateDoneForm();
        } else if (this.selectedOption === 'pix' && this.tipoJogo === 'tradicional') {
            this.activePixModal();
            this.makeTraditionalPurchase();
        }
        if (this.selectedOption === 'pix' && this.tipoJogo === 'rifa') {
            this.makePurchase();
        } else if (this.selectedOption === 'saldo' && this.tipoJogo === 'rifa') {
            this.debitFromAccount();
        }
    }

    async makeTraditionalPurchase() {
        if (this.isPurchaseInProgress) {
            return;
        }
        if (this.purchaseValue < 1.00) {
            const toast = await this.toastCtrl.create({
                message: 'O valor mínimo de compra com pix é R$1,00.',
                position: 'top',
                duration: 6000,
                cssClass: 'toast-error'
            });
            this.isPurchaseInProgress = false;
            return await toast.present();
        }
        const loading = await this.loadingCtrl.create({
            message: 'Por favor aguarde...'
        });
        try {
            this.isPurchaseInProgress = true;
            this.dismissModal(true);
            await loading.present();

            const form = {...this.infoGame, captcha: this.captcha};
            const response: any = await this.apostaService.savePix({ form });
            const { pix } = response;

            const modal = await this.modalController.create({
                component: ModalPixComponent,
                keyboardClose: true,
                cssClass: 'modal-fab-bottom',
                componentProps: {
                    totalValue: this.purchaseValue / 100,
                    pix
                },
            });

            modal.onWillDismiss().then((res) => {
                if (res.data === true) {
                    this.openModalSuccessTraditional([response.data]);
                }
            });
            await loading.dismiss(true);
            return await modal.present();
        } catch (error) {
            await loading.present();
            const msg = error.error && error.status && error.status === 400 ? error.error : 'Ocorreu um erro inesperado ao tentar finalizar sua compra';
            const toast = await this.toastCtrl.create({
                message: msg,
                color: 'danger',
                position: 'top',
                duration: 6000,
                cssClass: 'toast-error'
            });
            await toast.present();
        } finally {
            await loading.dismiss();
            this.isDebitInProgress = false;
        }
    }


    async debitFromAccount() {
        if (this.isDebitInProgress) {
            return;
        }
        const loading = await this.loadingCtrl.create({ message: 'Estamos procurando cotas disponíveis. Por favor aguarde...' });
        await loading.present();
        this.isDebitInProgress = true;
        try {
            const info = await this.auth.getUserData();
            this.carteira = await this.user.saldo(info._id);
            const saldoTotal = this.carteira.saldo + this.carteira.saldo_bonus + this.carteira.saldo_premio;
            if (this.purchaseValue * 100 > saldoTotal) {
                const toast = await this.toastCtrl.create({
                    message: 'Saldo insuficiente.',
                    position: 'top',
                    duration: 6000,
                    cssClass: 'toast-error'
                });
                await loading.dismiss(true);
                return await toast.present();
            }

            const form: any = {
                rifaId: this.award._id,
                qtdCotas: this.quotes,
                type: 'wallet',
                captcha: this.captcha
            };

            const buyResponse: any = await this.cotasService.postBuyQuotes({ form });

            if (!!buyResponse.length) {
                this.dismissModal(true);
                this.debitEvent.emit(true);
                await loading.dismiss(true);
                this.openModalSuccess();
            }

        } catch (error) {
            await loading.present();
            const msg = error.error && error.status && error.status === 400 ? error.error : 'Ocorreu um erro inesperado ao tentar finalizar sua compra';
            const toast = await this.toastCtrl.create({
                message: msg,
                position: 'top',
                duration: 6000,
                cssClass: 'toast-error'
            });
            await toast.present();
        } finally {
            await loading.dismiss(true);
            this.isDebitInProgress = false;
        }
    }

    async makePurchase() {
        if (this.isPurchaseInProgress) {
            return;
        }
        if (this.purchaseValue < 5.00) {
            const toast = await this.toastCtrl.create({
                message: 'O valor mínimo de compra com pix é R$5,00.',
                position: 'top',
                duration: 6000,
                cssClass: 'toast-error'
            });
            this.isPurchaseInProgress = false;
            return await toast.present();
        }
        const loading = await this.loadingCtrl.create({
            message: 'Estamos procurando cotas disponíveis. Por favor aguarde...'
        });
        try {
            this.isPurchaseInProgress = true;
            this.dismissModal(true);
            await loading.present();

            const form: any = {
                rifaId: this.award._id,
                qtdCotas: this.quotes,
                type: 'pix',
                captcha: this.captcha
            };

            const response: any = await this.cotasService.postBuyQuotes({ form });
            const { cotas, pix } = response;
            const modal = await this.modalController.create({
                component: ModalPixComponent,
                keyboardClose: true,
                cssClass: 'modal-fab-bottom',
                componentProps: {
                    cotas,
                    pix,
                    totalValue: this.purchaseValue,
                },
            });

            modal.onWillDismiss().then((res) => {
                if (res.data === true) {
                    this.debitEvent.emit({ data: true });
                    this.openModalSuccess();
                }
            });
            await loading.dismiss(true);
            return await modal.present();
        } catch (error) {
            await loading.present();
            const msg = error.error && error.status && error.status === 400 ? error.error : 'Ocorreu um erro inesperado ao tentar finalizar sua compra';
            const toast = await this.toastCtrl.create({
                message: msg,
                color: 'Erro ao realizar a compra',
                position: 'top',
                duration: 6000,
                cssClass: 'toast-error'
            });
            await toast.present();
        } finally {
            await loading.dismiss(true);
            this.isDebitInProgress = false;
        }
    }


    async openModalSuccess() {
        const modal = await this.modalController.create({
            component: ModalDefaultComponent,
            keyboardClose: true,
            cssClass: 'modal-fab-bottom modal-default',
            componentProps: {
                texto: 'Pagamento realizado com sucesso! Seus números de rifa foram gerados.',
                icone: '/assets/icons/icon-cash.png',
                cssClass: 'success',
            }
        });
        modal.onWillDismiss().then(() => {
            this.debitEvent.emit({ data: true });
            this.dismissModal(true);
        });
        await modal.present();
    }

    dismissModal(data: boolean) {
        this.modalController.dismiss(data);
    }

    async openModalSuccessTraditional(response: any = null) {
        const modal = await this.modalController.create({
          component: ModalDefaultComponent,
          keyboardClose: true,
          backdropDismiss: true,
          cssClass: 'modal-fab-bottom modal-default',
          componentProps: {
            texto: 'Aposta realizada com sucesso!',
            icone: '/assets/icons/icon-figa.png',
            cssClass: 'success',
            btnLabel: 'Ver pule',
            close: true,
            btnLabel2: 'Jogar novamente',
            jogoInfo: { jogos: [this.infoGame] }
          }
        }
        );
        modal.onWillDismiss().then(async (info: any) => {
          await this.saveListPule(response);

          if (!info.data) {
            this.getMyGames();
            this.openModalPule();
          } else {
            const elDiv = document.getElementById('elScrolling');
            elDiv.scrollTop = 0;
          }
        });
        return await modal.present();
      }

      async saveListPule(response: any = null) {
        const list = await this.storage.get('list-pule');
        if (!list || !list.length) {
          await this.storage.set('list-pule', response != null ? response : [this.infoGame]);
        } else {
          list.unshift(response != null ? response[0] : this.infoGame);
          await this.storage.set('list-pule', []);
          await this.storage.set('list-pule', list);
        }
      }

      async getMyGames() {
        this.storage.get('list-games').then((list) => {
          if (!list || !list.length) {
            this.storage.set('list-games', [this.infoGame]);
          } else {
            list.unshift(this.infoGame);
            this.storage.set('list-games', []);
            this.storage.set('list-games', list);
          }
        });
      }

      async openModalPule() {
        const games = await this.storage.get('list-pule');
        await this.storage.set('list-pule', []);
        const modal = await this.modalController.create({
          component: ModalPuleComponent,
          keyboardClose: true,
          cssClass: 'modal-fab-bottom modal-pule modal-pule-comprovante',
          componentProps: {
            list: games,
            status: 'aposta',
            cssClass: 'style-comprovante'
          }
        }
        );

        modal.onWillDismiss().then((type: any) => {
          if (!type.data) {
            this.navCtrl.navigateRoot('menu/home');
          } else {
            const elDiv = document.getElementById('elScrolling');
            elDiv.scrollTop = 0;
          }
        });
        return await modal.present();
      }

    onCaptchaResponse(captcha: any) {
        this.captcha = captcha;
    }
}
