import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { RifasService } from 'src/services/rifas/rifas.service';
import * as moment from 'moment';

@Component({
  selector: 'modal-pule',
  templateUrl: 'modal-pule-component.html',
  styleUrls: ['modal-pule-component.scss']
})

export class ModalPuleComponent implements OnInit {

  imagemPule = '/assets/imgs/img-pule.svg';

  @Input() cssClass: string;
  @Input() status: any;
  @Input() list: any;
  @Input() multi: boolean = false;

  visibleQuotas = 40;
  create_at: any;
  premios: any;

  constructor(private modalCtrl: ModalController,
    private socialSharing: SocialSharing,
    private rifaService: RifasService,
    private platform: Platform,
  ) { }

  async ngOnInit() {
    this.multi = this.multi || false;
    this.create_at = new Date().toISOString();
  }

  verMais() {
    this.visibleQuotas = this.list[0].cotas.length;

  }

  quantidadeDeJogos(jogos) {
    let total = 0;
    jogos.sorteios.forEach(sorteio => {
      sorteio.tipo.forEach(tipo => {
        tipo.games.forEach(game => {
          total = total + game.sorteios.length;
        });
      });
    });

    return total;
  }

  getId(jogo) {
    let response = '';
    jogo.sorteios.forEach(sorteio => {
      sorteio.tipo.forEach(tipo => {
        tipo.games.forEach(game => {
          response = game._id;
        });
      });
    });

    return response;
  }

  getInt(value, count) {
    return (value / count).toString().replace(/([0-9]{2})$/g, ',$1');
  }

  getTotal(value) {
    // return (value * count);
    return value.toString().replace(/([0-9]{2})$/g, ',$1');
  }

  dateFormatSorteio(dia, format) {
    dia = dia.split('T', 1);
    return this.dateFormat(dia[0], format);
  }

  dateFormat(dia, format) {
    return moment(dia).format(format);
  }

  onClose() {
    this.modalCtrl.dismiss();
  }

  getFormattedDate(date: Date): string {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  onJogar() {
    this.modalCtrl.dismiss({ jogar: true });
  }

  formatNumberWithLeadingZeros(number) {
    return number.toString().padStart(5, '0');
  }

  onshare() {
    if (this.platform.is('cordova')) {
      this.socialSharing.share("Acabei de realizar meu jogo, faça o seu tambem, não fique de fora!", "Animalbet", null, 'https://animal.bet');
    } else {
      window.open('mailto:mail@gmail.com?subject=' + encodeURIComponent("Animalbet") + '&body=' + encodeURIComponent("Acabei de realizar meu jogo, faça o seu tambem, não fique de fora! acesse: https://animal.bet"), '_blank');
    }
  }
}
