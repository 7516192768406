import { AfterViewInit, Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { IonDatetime, ModalController, NavParams } from '@ionic/angular';
import { ApostaService } from 'src/services/user/aposta.service';
import * as moment from 'moment';
import * as _ from 'underscore';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'modal-selecionar-dia',
  templateUrl: 'modal-selecionar-dia-component.html',
  styleUrls: ['modal-selecionar-dia-component.scss']
})

export class ModalSelecionarDiaComponent implements OnInit, AfterViewInit {

  @Output() selectedDateEvent = new EventEmitter();

  listType: any[] = [];
  horarioPadrao: any;
  horariosPersonalizados: any;
  dia: any;
  labelDay: any;
  isPWA: any;
  diaSelecionado: any;
  selectDay: any;
  isCalendar = false;
  sorteios: any[] = [];
  isDone = false;
  emptystate = false;

  constructor(private modalCtrl: ModalController,
    private navParams: NavParams,
    private apostaService: ApostaService
  ) {

    moment.locale('pt-br');
    this.diaSelecionado = this.navParams.get('diaSelecionado');

    if (moment(this.diaSelecionado).isValid()) {
      this.selectDay = moment(this.diaSelecionado).format('YYYY-DD-MM');
      this.selectedDateEvent.emit(moment(this.diaSelecionado).format('YYYY-DD-MM'));
      this.selectedDayCalendar(moment(this.diaSelecionado).format('YYYY-DD-MM'));
    } else {
      const date = moment();
      this.selectDay = date.format('YYYY-MM-DD');
      this.selectedDateEvent.emit(date.format('YYYY-MM-DD'));
      this.selectedDayCalendar(date.format('YYYY-MM-DD'));
    }
    this.isPWA = this.navParams.get('isPWA');
  }

  async ngOnInit() {
  }

  async ngAfterViewInit() { }

  async selectedDayCalendar(day) {
    try {
      this.horarioPadrao = await this.apostaService.getHorariosPadrao();
      this.horariosPersonalizados = await this.apostaService.getHorariosPersonalizados(day);
    } catch (err) {
      console.error(err)
    }

    this.sorteios = [];
    this.dia = day;
    this.labelDay = moment(this.dia).format('DD/MM/YYYY');
    this.isCalendar = false;

    let horarioAuxiliar = this.horarioPadrao;

    if (this.horariosPersonalizados.length > 0) {
      for (const item of horarioAuxiliar) {
        const hasHorarioPersonalizado = this.horariosPersonalizados.find(el => el.tipo === item.tipo);
        if (hasHorarioPersonalizado) {
          item.hora = hasHorarioPersonalizado.hora;

          const dataOficial = moment(hasHorarioPersonalizado.dataOficial);
          const novaData = moment(hasHorarioPersonalizado.novaData);

          if (!novaData.isSame(dataOficial, 'day')) {
            item.novaData = hasHorarioPersonalizado.novaData;
          }
        }
      }
    }

    let resultadosHorario;

    const weekday = moment(day).format('dddd');
    const hour = moment(moment().format('HH:mm'), 'HH:mm a');
    const today = moment(this.dia).isSame(moment(), 'day');
    if (this.labelDay === '25/12/2021' || this.labelDay === '01/01/2022') {
      this.listType = [];
      return;
    }
    switch (weekday) {
      case 'Quarta-feira':
      case 'Sábado':
        resultadosHorario = horarioAuxiliar.filter(
          item => item.tipo !== 'PTN'
        );
        break;
      case 'Domingo':
        resultadosHorario = horarioAuxiliar.filter(item =>
          item.tipo !== 'PTN' &&
          item.tipo !== 'COR' &&
          item.tipo !== 'FED'
        );
        break;
      case 'Segunda-feira':
      case 'Terça-feira':
      case 'Quinta-feira':
      case 'Sexta-feira':
        resultadosHorario = horarioAuxiliar.filter(
          item => item.tipo !== 'FED'
        );
        break;
      default:
        resultadosHorario = [];
        break;
    }

    const filtrarDataSorteio = resultadosHorario.filter(elemento => {
      const elementoHora = moment(elemento.hora, 'HH:mm');
      const hoje = moment().format('YYYY-MM-DD');
      const novaData = moment(elemento.novaData).format('YYYY-MM-DD');
      
      if (elemento.novaData) {
        const elementoDataHora = moment(`${novaData} ${elemento.hora}`, 'YYYY-MM-DD HH:mm');
        const agora = moment().format('YYYY-MM-DD HH:mm');
        
        const dataHoraAnterior = elementoDataHora.isBefore(agora);
    
        if (dataHoraAnterior) {
          return false;
        }
      }
    
      const condicaoOriginal = today && hour.isBefore(elementoHora) || !today;
      const condicaoDataDiferente = !moment(hoje).isSame(novaData);
    
      return condicaoOriginal || condicaoDataDiferente;
    });
    this.listType = filtrarDataSorteio
  }

  isItemSelected(item): boolean {
    return this.sorteios.includes(item);
  }

  onSelectType(item) {
    if (this.isItemSelected(item)) {
      const index = this.sorteios.indexOf(item);
      this.sorteios.splice(index, 1);
    } else {
      this.sorteios.push(item);
    }
    this.isDone = this.sorteios.length > 0;
  }

  onConfirm() {
    // moment(this.dia).format('LL');   // 16 de Março de 2020
    this.modalCtrl.dismiss({
      dia: moment(this.dia).format('DD/MM/YYYY'),
      sorteios: this.sorteios
    });
  }

  onClose() {
    this.modalCtrl.dismiss();
  }
}
