import { ModalTipoApostaComponent } from '../modal-tipo-aposta/modal-tipo-aposta-component';
import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Premio } from 'src/services/rifas/mock';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'moda-bet-type',
  templateUrl: 'modal-bet-type-component.html',
  styleUrls: ['modal-bet-type-component.scss']
})
export class ModalBetTypeComponent {
  @ViewChild('element', { static: false }) element: ElementRef;

  @Input() award: Premio;
  @Input() quotes: number;

  isPWA = false;
  partURL = '/';
  usuario: any;

  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private router: Router,
  ) { }

  dismissModal() {
    this.modalController.dismiss();
  }

  async ionViewDidEnter() {
    const contenttWidth = this.element ? this.element.nativeElement.clientWidth : 0;

    if (contenttWidth >= 992 || (this.platform.width() >= 992 && this.platform.isLandscape())) {
      this.isPWA = true;
      this.partURL = '/menu/';
    }
  }


  openGame(type) {
    this.router.navigate([this.partURL + 'jogo'], {
      replaceUrl: true,
      state: {
        data: type,
        usuario: this.usuario
      }
    });
  }

  async onModalBetType() {
    this.modalController.dismiss();

    if (this.isPWA) {
      this.openGame(null);
    } else {
      const modal = await this.modalController.create({
        component: ModalTipoApostaComponent,
        keyboardClose: true,
        cssClass: 'modal-fab-bottom modal-bet-type',
        componentProps: {}
      }
      );

      modal.onWillDismiss().then((type: any) => {
        if (type && type.data) {
          this.openGame(type.data);
        }
      });
      return await modal.present();
    }
  }

}