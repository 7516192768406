import { LoadingController, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from '../api/request.service';
import { StorageService } from '../storage/storage';
import { URL_BASE } from 'src/util/constants';
import { UserService } from '../user/user.service';

export const USER_DATA = 'is-login';
export const TOKEN_AUTH = '_animal_tokenAuthorization';
export const USER_ACCESS = '_user_acess';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  result: any = {};
  code = '';
  text = '';

  constructor(private http: RequestService,
    private loadingCtrl: LoadingController,
    private toastController: ToastController,
    private router: Router,
    private storage: StorageService) {
  }

  public getAuthorizationToken() {
    return localStorage.getItem(TOKEN_AUTH);
  }

  public async clearAuthorizationToken() {
    localStorage.removeItem(TOKEN_AUTH);
    localStorage.removeItem(USER_DATA);
  }

  public async resetAuthorizationToken(token: string) {
    await this.storage.set(TOKEN_AUTH, token);
  }

  public getUserFromStorage() {
    const userData = localStorage.getItem(USER_DATA);
    if (userData) {
      return JSON.parse(userData);
    }
    return null;
  }

  public async getUserData() {
    const userData = await this.storage.get(USER_DATA);
    if (userData) {
      return JSON.parse(userData);
    }
    const user: any = await this.http.get(URL_BASE + '/user/me');
    if (user._id) {
      return JSON.parse(await this.storage.get(USER_DATA));
    } else {
      await this.clearAuthorizationToken();
      return false;
    }
  }

  public async getProfile(id) {
    const user: any = await this.http.get(URL_BASE + '/user/show?id=' + id, []);
    if (user._id) {
      return user;
    } else {
      return false;
    }
  }

  public async setUserData(data: object) {
    if (data) {
      const userData = JSON.stringify(data);
      await this.storage.set(USER_DATA, userData);
    }
  }

  public isLogged() {
    return !!localStorage.getItem(TOKEN_AUTH);
  }

  async setAccess() {
    await this.storage.set(USER_ACCESS, '1');
  }

  async getAccess() {
    return !!await this.storage.get(USER_ACCESS);
  }

  async validaEmail(params, withoutLoading?: boolean) {
    let loading = null;
    if (!withoutLoading) {
      loading = await this.loadingCtrl.create();
      await loading.present();
    }
    const result = await this.http.post(URL_BASE + '/valida-email', params);
    if (!withoutLoading) {
      await loading.dismiss();
    }
    return result;
  }

  async login(params, redirect = '/menu/home', modal?) {
    const loading = await this.loadingCtrl.create();
    await loading.present();

    this.result = await this.http.post(URL_BASE + '/authenticate', params);
    if (this.result && this.result.token && this.result.user) {
      await this.storage.set('loginType', 'email');
      const cupom = await this.storage.get('cupom');
      await this.storage.remove('show-modal-influencer-' + cupom);
      await this.storage.remove('cupom');
      await this.setUserData(this.result.user);
      await this.resetAuthorizationToken(this.result.token);
      await loading.dismiss();
      this.router.navigate([redirect]);
    } else {
      await loading.dismiss();
      await this.errorLogin();
    }
  }

  async loginWithFacebookGoogle(params) {
    this.result = await this.http.post(URL_BASE + '/authenticate/social', params);
    if (this.result && this.result.token) {
      const login = await this.getDataUser();
      if (!login) {
        await this.errorLogin();
      }
    } else {
      await this.errorLogin();
    }
  }

  async errorLogin() {
    const toast = await this.toastController.create({
      cssClass: 'modal-toast',
      message: 'Seu e-mail e/ou senha estão incorretos, confira seus dados e tente novamente.',
      position: 'top',
      duration: 2000
    });
    await toast.present();
  }

  async errorBlock() {
    const toast = await this.toastController.create({
      cssClass: 'modal-toast',
      message: 'Você encontra-se bloqueado entre em contado com a administracao.',
      position: 'top',
      duration: 2000
    });
    await toast.present();
  }

  async firstAccess() {
    if (this.getAccess()) {
      this.router.navigateByUrl('/tabs/home');
    }
  }

  async getDataUser() {
    const user: any = await this.http.get(URL_BASE + '/user/me');
    if (user._id) {
      const info = {
        _id: user._id,
        name: `${user.name} ${(user.surname) ? user.surname : ''}`,
        phone: user.phone,
        fl_ativo: user.fl_ativo,
        dt_nascimento: user.dt_nascimento,
        email: user.email,
        cpf: user.cpf
      };
      await this.setUserData(info);
      return true;
    } else {
      await this.errorBlock();
      return false;
    }
  }
}


