import { Directive, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective implements OnInit {

  @Input() public apenasNumeros: FormControl;

  public nameRegexp = /[0-9]/g;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.apenasNumeros) {
      return;
    }

    this.apenasNumeros.valueChanges.subscribe((valor) => {
      const nomeTratado = this.apenasNumeros.value.match(this.nameRegexp);

      if (nomeTratado) {
        if (nomeTratado.join('').length !== this.apenasNumeros.value.length) {
          this.apenasNumeros.patchValue(nomeTratado.join(''));
        }
      } else if (valor.length) {
        this.apenasNumeros.patchValue('');
      }
    });
  }
}
