import { BrMaskerModule } from 'br-mask';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ListResultsComponent } from './list-results-component/list-results-component';
import { AnimalSlidesComponent } from './animal-slides-component/animal-slides-component';
import { AddValorComponent } from './add-valor-component/add-valor-component';
import { TipoJogoComponent } from './tipo-jogo-component/tipo-jogo-component';
import { CalendarModule } from 'ion4-calendar';
import { CalendarComponent } from './calendar-component/calendar-component';
import { CardJogoComponent } from './card-jogo/card-jogo.component';
import { FooterComponent } from './footer-component/footer-component';
import { RaffleRewardSlider } from './raffle-reward/raffle-reward-slider';
import {CardExtractComponent} from './card-extract-component/card-extract-component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    CalendarModule,
    ReactiveFormsModule,
    BrMaskerModule,
    RouterModule,
  ],
  declarations: [
    ListResultsComponent,
    AnimalSlidesComponent,
    AddValorComponent,
    TipoJogoComponent,
    CalendarComponent,
    CardJogoComponent,
    FooterComponent,
    RaffleRewardSlider,
    CardExtractComponent
  ],
  exports: [
    ListResultsComponent,
    AnimalSlidesComponent,
    AddValorComponent,
    TipoJogoComponent,
    CalendarComponent,
    CardJogoComponent,
    FooterComponent,
    RaffleRewardSlider,
    CardExtractComponent
  ]
})

export class ComponentsModule {
}
