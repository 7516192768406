import { Injectable } from '@angular/core';
import { RequestService } from '../api/request.service';
import { URL_BASE } from 'src/util/constants';

@Injectable({
    providedIn: 'root'
})
export class ApostaService {

    constructor(
        private http: RequestService
    ) { }

    async apostar(params) {
        return await this.http.post(URL_BASE + '/aposta', params);
    }

    async getPorDia(params) {
        const t = await this.http.post(URL_BASE + '/aposta/por/dia', params);
        console.log(t);
        return t;
    }

    async buscar(params, page?, sort?, naoSorteado = false) {
        let infopage = '&page=1';
        if (page) {
            infopage = '&page=' + page;
        }
        if (sort) {
            infopage = infopage + '&sort=' + sort;
        }
        if (naoSorteado) {
            infopage = infopage + '&not=' + naoSorteado;
        }
        return await this.http.get(URL_BASE + '/aposta/jogos?id=' + params._id + infopage);
    }


    async saveAposta(info) {
        localStorage.setItem('draft-game', JSON.stringify(info));
    }

    async savePix(params) {
        return await this.http.post(URL_BASE + '/aposta/pix', params);
    }

    async clearAposta() {
        localStorage.removeItem('draft-game');
    }

    async getAposta() {
        const item = localStorage.getItem('draft-game');
        if (item) {
            return JSON.parse(item);
        } else {
            return false;
        }
    }

    async getHorariosPadrao() {
        return await this.http.get(URL_BASE + '/resultados/horario-padrao');
    }

    async getHorariosPersonalizados(date) {
        return await this.http.post(URL_BASE + '/resultados/horario-personalizados', { date });
    }
}
