import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { PreAuthGuard } from '../guards/pre-auth.guard';
import { CadastroRealizadoModule } from './cadastro-realizado/cadastro-realizado.module';

// @ts-ignore
const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    loadChildren: './welcome/welcome.module#WelcomePageModule',
    canLoad: [ PreAuthGuard ],
  },
  {
    path: 'welcome/:cupom',
    loadChildren: './welcome/welcome.module#WelcomePageModule',
    canLoad: [ PreAuthGuard ],
  },
  {
    path: 'central-duvidas',
    loadChildren: './central-duvidas/central-duvidas.module#CentralDuvidasPageModule',
  },
  {
    path: 'aprender-jogar',
    loadChildren: './aprender-jogar/aprender-jogar.module#AprenderJogarPageModule',
  },
  {
    path: 'termo-uso',
    loadChildren: './termo-uso/termo-uso.module#TermoUsoPageModule',
  },
  {
    path: 'sobre',
    loadChildren: './sobre/sobre.module#SobrePageModule',
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginPageModule',
    canLoad: [ PreAuthGuard ],
  },
  {
    path: 'login/:cupom',
    loadChildren: './login/login.module#LoginPageModule',
    canLoad: [ PreAuthGuard ],
  },
  {
    path: 'criar-conta',
    loadChildren: './criar-conta/criar-conta.module#CriarContaPageModule',
    canLoad: [ PreAuthGuard ],
  },
  {
    path: 'criar-conta/:cupom',
    loadChildren: './criar-conta/criar-conta.module#CriarContaPageModule',
    canLoad: [ PreAuthGuard ],
  },
  {
    path: 'cadastro-realizado',
    loadChildren: './cadastro-realizado/cadastro-realizado.module#CadastroRealizadoModule',
    // canLoad: [ AuthGuard ],
  },
  {
    path: 'menu',
    loadChildren: './menu/menu.module#MenuPageModule',
    canLoad: [ AuthGuard ],
  },
  {
    path: 'onboard',
    loadChildren: './onboard/onboard.module#OnboardPageModule',
    canLoad: [ AuthGuard ],
  },
  {
    path: 'profile',
    loadChildren: './profile/profile.module#ProfilePageModule',
    canLoad: [ AuthGuard ],
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule',
    canLoad: [ AuthGuard ],
  },
  {
    path: 'transferir',
    loadChildren: './transferir/transferir.module#TransferirPageModule',
    canLoad: [ AuthGuard ],
  },
  {
    path: 'depositar',
    loadChildren: './depositar/depositar.module#DepositarPageModule',
    canLoad: [ AuthGuard ],
  },
  {
    path: 'privacidade',
    loadChildren: './privacidade/privacidade.module#PrivacidadePageModule',
    canLoad: [ AuthGuard ],
  },
  {
    path: 'reclamacoes',
    loadChildren: './reclamacoes/reclamacoes.module#ReclamacoesPageModule',
    canLoad: [ AuthGuard ],
  },
  {
    path: 'quem',
    loadChildren: './quem/quem.module#QuemPageModule',
    canLoad: [ AuthGuard ],
  },
  {
    path: 'responsavel',
    loadChildren: './responsavel/responsavel.module#ResponsavelPageModule',
    canLoad: [ AuthGuard ],
  },
  {
    path: 'jogo',
    loadChildren: './jogo/jogo.module#JogoPageModule',
    canLoad: [ AuthGuard ],
  },
  {
    path: '**',
    redirectTo: 'welcome',
  },
  {
    path: 'rifas',
    loadChildren: './rifas/rifas.module#RifasPageModule',
    canLoad: [ AuthGuard ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
