import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { RevendaService } from 'src/services/user/revenda.service';
import { Router } from '@angular/router';
import { from, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'modal-associar-cupom',
  templateUrl: 'modal-associar-cupom-component.html',
  styleUrls: ['modal-associar-cupom-component.scss']
})

export class ModalAssociarCupomComponent {

  codigoInfluencer: any;
  cupomInfluencer: any;
  usuario: any;
  jaPossuiCupom: any = false;
  cupomJaAssociado: any;
  carregando = true;

  constructor(
    private revendaService: RevendaService,
    private loadingCtrl: LoadingController,
    private router: Router,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
  ) {

  }

  async ionViewDidEnter() {

    if (this.codigoInfluencer) {

      const loading = await this.loadingCtrl.create();

      from(
        this.revendaService.cupomUser({})
      ).
      pipe(
        mergeMap( cupomUser => {
          loading.present();
          this.carregando = true;
          this.cupomJaAssociado = cupomUser;
          if (this.cupomJaAssociado.length === undefined || this.cupomJaAssociado.length === 0) {
            return from(this.revendaService.show({codigo: this.codigoInfluencer}));
          } else {
            this.jaPossuiCupom = true;
          }
          return of(null);
        }),
        mergeMap( dadosInfluecer => {
          if (dadosInfluecer) {
            if (dadosInfluecer.length !== 0) {
              this.cupomInfluencer = dadosInfluecer;
              this.cupomInfluencer = this.cupomInfluencer[0];

              const cupomAssociado = {
                influencer: this.cupomInfluencer,
                userApostador: this.usuario,
                porcentagemDesconto: this.cupomInfluencer.percentPrimeiraRecarga,
                primeiraRecarga: true,
                tipoCarteira: 'BONUS',
              };

              return from(this.revendaService.associarCupom(cupomAssociado));
            } else {
              this.toast('Cupom inválido');
              this.onClose();
            }
          }
          return of(null);
        })
      ).subscribe( async () =>  {
        loading.dismiss();
        this.carregando = false;
      }, error => {
        console.log('4', error);
        loading.dismiss();
        this.toast('Erro ao acessar a base de dados');
      });
    }
  }

  async toast(messagem) {
    const toast = await this.toastCtrl.create({
      message:  messagem,
      cssClass: 'toast-error',
      position: 'top',
      duration: 3000
    });
    toast.present();
  }


  onClose() {
    this.modalCtrl.dismiss(false);
  }
}
